import React from 'react';

import { useDraggable } from '@dnd-kit/core';

export type DraggableProps = {
  id: string;
  moveTargets?: string[];
  disabled?: boolean;
  element?: React.ComponentType;
};

export default function Draggable(props: React.PropsWithChildren<DraggableProps>) {
  const Element = props.element || 'div';
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: { targets: props.moveTargets },
    disabled: props.disabled
  });

  return (
    <Element ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </Element>
  );
}
