import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import { PersonalVoiceActionType } from 'interfaces';
import { get } from 'lodash';
import { RootState, useDispatch, useSelector } from 'store';
import { actions as personalVoiceActions } from 'store/personalVoices';

import ConfirmModal from './ConfirmModal';

export const ConfirmPersonalVoiceActionModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { confirmItemAction, confirmItem } = useSelector((state: RootState) => state.personalVoices);

  // sidebar closes when an outside click is detected except when a modal is open
  // delay dispatch to keep sidebar open

  const handleCancel = useCallback(() => {
    setTimeout(() => {
      dispatch(personalVoiceActions.setConfirmItem(undefined));
    }, 250);
  }, [dispatch]);

  const handleConfirm = () => {
    switch (confirmItemAction) {
      case PersonalVoiceActionType.Delete:
        if (confirmItem) {
          const voiceId = (get(confirmItem, 'name') || '').replace('PVL:', '');

          if (voiceId) {
            dispatch(personalVoiceActions.deletePersonalVoice(voiceId));
          }
        }

        break;
    }

    // sidebar closes when an outside click is detected except when a modal is open
    // delay dispatch to keep sidebar open

    setTimeout(() => {
      dispatch(personalVoiceActions.setConfirmItem(undefined));
    }, 250);
  };

  const { title, description } = useMemo(() => {
    if (!confirmItemAction) {
      return {
        title: '',
        description: ''
      };
    }

    switch (confirmItemAction) {
      default:
        return {
          title: t('Please Confirm'),
          description: t('are_you_sure_custom_voice', { verb: confirmItemAction })
        };
    }
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmItemAction]);

  if (confirmItem && confirmItemAction) {
    return <ConfirmModal id={confirmItem.idQualified} title={title} description={description} onConfirm={handleConfirm} onCancel={handleCancel} />;
  }
  return null;
};

export default ConfirmPersonalVoiceActionModal;
