import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import DocxIcon from 'assets/icons/file-types/docx';
import EPubIcon from 'assets/icons/file-types/epub';
import PdfIcon from 'assets/icons/file-types/pdf';
import TxtIcon from 'assets/icons/file-types/txt';
import XlsxIcon from 'assets/icons/file-types/xlsx';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES } from 'interfaces/import';
import { omit, pull } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'store';
import { actions as authActions } from 'store/auth';
import { actions as importActions } from 'store/import';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';
import { v4 } from 'uuid';

import { useImportFile } from './hooks/useImportFile';
import { useListenFile } from './hooks/useListenFile';
import { usePdfUpsell } from './hooks/usePdfUpsell';

// As dropzone captures drag'n'drop files on entire screen -
// it makes sense that only last one is responsible for capturing drag'n'drop events
// There can be multiple dropzones rendered at a time: e.g. initial library state, upload dialog, etc
const dropzones: string[] = [];

function getDropzoneId() {
  const dropzoneId = v4();
  dropzones.push(dropzoneId);
  return dropzoneId;
}

const acceptedMimeTypes = Object.fromEntries(pull(Object.values(ALLOWED_MIME_TYPES), ALLOWED_MIME_TYPES.GDOC).map(mimeType => [mimeType, []]));

interface DropzoneProps {
  fullWidth?: boolean;
  autoHeight?: boolean;
  className?: string;
}

export const Dropzone: React.FC<DropzoneProps> = ({ fullWidth, autoHeight, className }) => {
  const { t } = useTranslation('common');
  const id = useMemo(getDropzoneId, []);
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: acceptedMimeTypes
  });

  const rootProps = useMemo(
    () => omit(getRootProps({ isFocused, isDragAccept, isDragReject }), ['isFocused', 'isDragAccept', 'isDragReject']),
    [getRootProps, isFocused, isDragAccept, isDragReject]
  );

  const [offset, setOffset] = useState<number | null>(null);

  const importFile = useImportFile();
  const listenFile = useListenFile();
  const { checkForUpsell } = usePdfUpsell();

  const handleFileImported = useCallback(
    (id: string, file: File, itemId: string, instantListening: boolean) => {
      setCustomAccountSetting('fileUploaded', 'true');
      dispatch(authActions.setFileUploaded(true));
      dispatch(importActions.setImportFileCompleted({ id, itemId }));

      if (instantListening) {
        dispatch(importActions.closeImportDialog());
        listenFile(itemId, file.type, true);
      }
    },
    [listenFile, dispatch]
  );

  useEffect(() => {
    if (!acceptedFiles.length) return;

    const instantListening = acceptedFiles.length === 1;

    logSegmentEvent('web_app_batch_uploading_count', { count: acceptedFiles.length });

    checkForUpsell(acceptedFiles.length).then(shouldUpsell => {
      if (shouldUpsell) return;

      for (const file of acceptedFiles) {
        const fileId = v4();
        importFile({ data: file, source: 'Local', instantListening })
          .then(itemId => {
            if (!itemId) throw new Error('import failed');
            handleFileImported(fileId, file, itemId, instantListening);
          })
          .catch(() => dispatch(importActions.setImportFileFailed(fileId)));
        dispatch(importActions.importFile({ id: fileId, file }));
      }

      dispatch(importActions.closeImportDialog());
    });
  }, [dispatch, acceptedFiles, importFile, listenFile, handleFileImported, checkForUpsell]);

  const handleFileDragEnter = useCallback(
    (e: DragEvent) => {
      const { dataTransfer } = e;

      if (dataTransfer && dataTransfer.types.includes('Files')) {
        dataTransfer.dropEffect = 'copy';
        setIsDragging(dropzones[dropzones.length - 1] === id);
      }
    },
    [id]
  );

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    if (rootRef.current === e.target) {
      setIsDragging(false);
    }
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    return () => {
      const idx = dropzones.indexOf(id);
      if (idx !== -1) {
        dropzones.splice(idx, 1);
      }
    };
  }, [id]);

  useEffect(() => {
    document.body.addEventListener('dragenter', handleFileDragEnter, true);
    document.body.addEventListener('dragend', handleDragEnd, true);
    document.body.addEventListener('drop', handleDragEnd, true);

    return () => {
      document.body.removeEventListener('dragenter', handleFileDragEnter, true);
      document.body.removeEventListener('dragend', handleDragEnd, true);
      document.body.removeEventListener('drop', handleDragEnd, true);
    };
  }, [handleFileDragEnter, handleDragEnd]);

  useEffect(() => {
    if (isDragging && dropzoneRef.current) {
      dropzoneRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isDragging]);

  useEffect(() => {
    if (!autoHeight) return;

    function calculateTopOffset() {
      if (!dropzoneRef.current) return;

      let scrollTop = 0;
      let node: HTMLElement | null = dropzoneRef.current;

      while (node && !scrollTop) {
        scrollTop = node.scrollTop;
        node = node.parentElement;
      }

      const offsetTop = dropzoneRef.current.getBoundingClientRect().top;

      setOffset(Math.floor(offsetTop + scrollTop));
    }

    window.addEventListener('resize', calculateTopOffset);
    calculateTopOffset();

    return () => window.removeEventListener('resize', calculateTopOffset);
  }, [autoHeight]);

  return (
    <div className="font-ABCDiatype cursor-pointer">
      <div
        className={twMerge(
          'relative rounded-[10px] overflow-hidden',
          !fullWidth && 'border',
          !fullWidth && !isDragging ? 'border-glass-300' : 'border-transparent'
        )}
        style={
          fullWidth
            ? {
                backgroundImage: !isDragging
                  ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238791A0FF' stroke-width='2' stroke-dasharray='10%2c 5' stroke-dashoffset='4' stroke-linecap='round'/%3e%3c/svg%3e")`
                  : undefined
              }
            : undefined
        }
      >
        {createPortal(
          <div
            className={twMerge('fixed left-0 top-0 right-0 bottom-0 bg-transparent z-[9999]', !isDragging && 'hidden')}
            {...rootProps}
            ref={rootRef}
            onDragLeave={handleDragLeave}
          />,
          document.body,
          `import_local_files_${id}`
        )}

        <div className={twMerge(isDragging && 'pointer-events-none')} ref={dropzoneRef}>
          <div data-dropzone="true" {...rootProps}>
            <input type="file" {...getInputProps()} />
            <div
              className={twMerge(
                'absolute left-0 top-0 bottom-0 right-0 rounded-[10px] pb-4 border-solid border-2 transition duration-150',
                'flex items-end justify-center',
                isDragging ? 'opacity-100 border-electric-350 bg-[#EEEEFFCD] z-50' : 'opacity-0 border-transparent'
              )}
            >
              {isDragging && <span className="rounded-full text-glass-0 bg-electric-350 py-3 px-6">{t('Drop your files to start listening immediately')}</span>}
            </div>
            <div
              className={twMerge('relative p-8 flex flex-col text-glass-500 items-center justify-center', className)}
              style={{
                minHeight: offset !== null ? `calc(100vh - ${offset + 20}px)` : 0
              }}
            >
              <div className="flex items-center justify-center gap-3.5 mb-5">
                <DocxIcon />
                <EPubIcon />
                <PdfIcon />
                <XlsxIcon />
                <TxtIcon />
              </div>
              <p>{t('Drag and drop your documents here')}</p>
              <p>{t('or')}</p>
              <p className="mt-1">
                <button
                  className={twMerge(
                    'flex justify-center flex-initial min-w-40',
                    'py-2 px-6 rounded-[8px] transition-colors',
                    'text-sm font-medium text-glass-0 bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
                  )}
                >
                  {t('Upload from Device')}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
