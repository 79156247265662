import React, { Fragment, MouseEvent, useMemo, useState } from 'react';

import ArrowDown from 'assets/icons/arrow-down';
import ArrowUp from 'assets/icons/arrow-up';
import DeleteIcon from 'assets/icons/delete';
import Diamond from 'assets/icons/diamond';
import DotsVerticalIcon from 'assets/icons/dots-vertical';
import DownloadMp3 from 'assets/icons/downloadMp3';
import DownloadVoiceOver from 'assets/icons/downloadVoiceOver';
import MoveToIcon from 'assets/icons/moveTo';
import NewFolderIcon from 'assets/icons/newFolder';
import OpenIcon from 'assets/icons/open';
import OpenOriginalIcon from 'assets/icons/openOriginal';
import RenameIcon from 'assets/icons/rename';
import ShareIcon from 'assets/icons/share';
import { IsDownloadMp3EnabledVariant } from 'constants/featureDefinitions';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import useMediaQuery from 'hooks/useMediaQuery';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ItemActionType } from 'interfaces';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage, isItemPage } from 'utils';

import { Menu, Transition } from '@headlessui/react';

interface LibraryItemActionMenuProps {
  hasOriginal?: boolean;
  isRow?: boolean;
  isFolder?: boolean;
  isCard?: boolean;
  arrowIcons?: boolean;
  isInstantListening?: boolean;
  isTrash?: boolean;
  titleWidthOverride?: number;
  hasVoiceOverCrossSell?: boolean;
  downloadMp3?: boolean;
  isPremium?: boolean;
  onAction: (actionType: ItemActionType) => void;
  hideOnMouseOut?: boolean;
}

export const ItemActionMenu: React.FC<LibraryItemActionMenuProps> = ({
  hasOriginal,
  isFolder,
  isTrash,
  arrowIcons,
  isInstantListening,
  isCard,
  titleWidthOverride,
  onAction,
  hasVoiceOverCrossSell,
  downloadMp3,
  isRow,
  isPremium,
  hideOnMouseOut
}) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(true);
  const [containerDiv, setContainerDiv] = useState<HTMLDivElement | null>(null);

  const downloadMp3Variant = useFeatureVariant(FeatureNameEnum.IS_DOWNLOAD_MP3_ENABLED);
  const isDownloadMp3Enabled = useMemo(
    () => isFeatureVariantReady(downloadMp3Variant) && downloadMp3Variant.variant === IsDownloadMp3EnabledVariant.ENABLED,
    [downloadMp3Variant]
  );
  const { matches: isTablet } = useMediaQuery('(min-width: 768px)');

  const handleActionClick = (e: MouseEvent, actionType: ItemActionType) => {
    e.preventDefault();
    e.stopPropagation();

    if (hideOnMouseOut) {
      setIsOpen(false);
    }

    onAction(actionType);
  };

  const containerWidth: number = useMemo(() => {
    if (containerDiv) {
      return containerDiv.getBoundingClientRect().width;
    }

    return 0;
  }, [containerDiv]);

  const rightOffset: number = useMemo(() => {
    if (arrowIcons) {
      if (titleWidthOverride) {
        const offset = titleWidthOverride - containerWidth;
        return offset < 0 ? offset : 0;
      }

      return -40;
    }

    return 0;
    // ESLint: React Hook useMemo has a missing dependency: 'arrowIcons'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleWidthOverride, containerWidth]);

  const DownloadMp3MenuItem = useMemo(() => {
    return (
      <Menu.Item>
        {({ active }) => (
          <button
            className={twMerge(
              active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
              !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
              active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
              !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
              'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
            )}
            onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.DownloadMp3)}
          >
            <div className="flex h-5 w-5 items-center justify-center">
              <DownloadMp3
                className={twMerge(
                  (isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350',
                  'h-[16px] w-[16px] text-gray-500 group-hover:text-gray-600'
                )}
                aria-hidden="true"
              />
            </div>
            {t('Download as MP3')}
            {!isPremium && (
              <Diamond data-tip data-for="lock" className="[&>g>#diamond-background]:fill-glass-0 dark:[&>g>#diamond-background]:fill-glass-700" />
            )}
          </button>
        )}
      </Menu.Item>
    );
    // ESLint: React Hook useMemo has missing dependencies: 'handleActionClick' and 't'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstantListening, isPremium]);

  return (
    <Menu as="div" className="inline-flex te,'text-left">
      {({ open }) => (
        <Fragment>
          <Menu.Button
            className={twMerge(
              'z-1 mr-0.5 inline-flex cursor-pointer items-center justify-end rounded-sm bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none',
              hideOnMouseOut && !open && 'invisible group-hover:visible'
            )}
            onClick={() => setIsOpen(true)}
          >
            <span className="sr-only">{t('Open options')}</span>
            {arrowIcons ? (
              open ? (
                <ArrowUp
                  style={{ minWidth: '16px' }}
                  className={twMerge(
                    'ml-0',
                    'h-4',
                    'w-4',
                    'cursor-pointer',
                    '[&>path]:fill-glass-700',
                    (isItemOrSharePage() || isInstantListening) && 'dark:[&>path]:fill-glass-0'
                  )}
                  aria-hidden="true"
                />
              ) : (
                <ArrowDown
                  style={{ minWidth: '16px' }}
                  className={twMerge(
                    'ml-0',
                    'h-4',
                    'w-4',
                    'cursor-pointer',
                    '[&>path]:fill-glass-700',
                    (isItemOrSharePage() || isInstantListening) && 'dark:[&>path]:fill-glass-0'
                  )}
                  aria-hidden="true"
                />
              )
            ) : (
              <div
                className={twMerge(
                  !isRow && !isCard
                    ? isFolder
                      ? 'hover:bg-[#E6EAEE] active:bg-[#DEE5EB] rounded-full'
                      : 'rounded-sm px-[2px]'
                    : isCard
                      ? 'hover:bg-[#E6EAEE] active:bg-[#DEE5EB] rounded-full'
                      : 'rounded-full hover:bg-[#E8EFFF] active:bg-[#D4DFF3] mr-1.5'
                )}
              >
                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
          </Menu.Button>
          <Transition
            show={open && isOpen}
            as="div"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
            className="relative z-50"
          >
            <Menu.Items static>
              <div
                ref={ref => setContainerDiv(ref)}
                className={twMerge(
                  arrowIcons ? (isItemOrSharePage() || isInstantListening ? 'top-10 md:top-8' : 'top-8') : 'top-2',
                  hasVoiceOverCrossSell || (isDownloadMp3Enabled && downloadMp3 && !isPremium) ? ' w-52' : 'w-44',
                  'z-50',
                  'absolute',
                  'origin-top-right',
                  'divide-y',
                  'divide-glass-300',
                  (isInstantListening || isItemOrSharePage()) && 'dark:divide-glass-600',
                  'overflow-hidden',
                  'rounded-[5px]',
                  'bg-glass-0',
                  (isInstantListening || isItemOrSharePage()) && 'dark:bg-glass-700',
                  'font-ABCDiatype',
                  'shadow-lg',
                  'ring-1',
                  'ring-glass-300',
                  (isInstantListening || isItemOrSharePage()) && 'dark:ring-glass-600',
                  'focus:outline-none'
                )}
                style={{
                  right: rightOffset
                }}
              >
                {isTrash ? (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={twMerge(
                            active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                            !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                            active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                            !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                            'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                          )}
                          onClick={(e: MouseEvent) => {
                            setIsOpen(false);
                            handleActionClick(e, ItemActionType.Restore);
                          }}
                        >
                          <RenameIcon className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')} aria-hidden="true" />
                          {t('Restore')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={twMerge(
                            active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                            !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                            active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                            !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                            'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                          )}
                          onClick={(e: MouseEvent) => {
                            setIsOpen(false);
                            handleActionClick(e, ItemActionType.DeleteArchivedItem);
                          }}
                        >
                          <MoveToIcon className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')} aria-hidden="true" />
                          {t('Delete forever')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ) : (
                  <>
                    <div className="py-1">
                      {isFolder && !isItemOrSharePage() && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={twMerge(
                                active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                                !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                                active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                                !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                                'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                              )}
                              onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.NewFolder)}
                            >
                              <NewFolderIcon
                                className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')}
                                aria-hidden="true"
                              />
                              {t('New Folder')}
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                              !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                              active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                              !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                              'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                            )}
                            onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.Rename)}
                          >
                            <RenameIcon className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')} aria-hidden="true" />
                            {t('Rename')}
                          </button>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                              !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                              active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                              !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                              'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                            )}
                            onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.Move)}
                          >
                            <MoveToIcon className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')} aria-hidden="true" />
                            {t('Move')}
                          </button>
                        )}
                      </Menu.Item>

                      {isItemOrSharePage() && !isTablet && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={twMerge(
                                active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                                !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                                active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                                !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                                'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                              )}
                              onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.Share)}
                            >
                              <div className="flex h-5 w-5 items-center justify-center">
                                <ShareIcon
                                  className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')}
                                  aria-hidden="true"
                                />
                              </div>
                              {t('Share')}
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>

                    {isItemPage() && hasOriginal && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                              !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                              active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                              !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                              'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                            )}
                            onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.OpenOriginal)}
                          >
                            <div className="flex h-5 w-5 items-center justify-center">
                              <OpenOriginalIcon
                                className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')}
                                aria-hidden="true"
                              />
                            </div>
                            {t('Open Original')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {!isFolder && !isItemOrSharePage() && (
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={twMerge(
                                active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                                !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                                active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                                !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                                'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                              )}
                              onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.Share)}
                            >
                              <div className="flex h-5 w-5 items-center justify-center">
                                <ShareIcon
                                  className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')}
                                  aria-hidden="true"
                                />
                              </div>
                              {t('Share')}
                            </button>
                          )}
                        </Menu.Item>

                        {!isItemPage() && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={twMerge(
                                  active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                                  !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                                  active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                                  !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                                  'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                                )}
                                onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.Open)}
                              >
                                <div className="flex h-5 w-5 items-center justify-center">
                                  <OpenIcon
                                    className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')}
                                    aria-hidden="true"
                                  />
                                </div>
                                {t('Open')}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        {hasVoiceOverCrossSell && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={twMerge(
                                  active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                                  !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                                  active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                                  !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                                  'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                                )}
                                onClick={(e: MouseEvent) => handleActionClick(e, ItemActionType.CrossSellVoiceOver)}
                              >
                                <div className="flex h-5 w-5 items-center justify-center">
                                  <DownloadVoiceOver
                                    className={twMerge(
                                      (isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350',
                                      'h-[16px] w-[16px] text-gray-500 group-hover:text-gray-600'
                                    )}
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="text-left">{t('Create Professional Voice Over')}</div>
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        {isDownloadMp3Enabled && downloadMp3 && DownloadMp3MenuItem}
                      </div>
                    )}
                    {isItemOrSharePage() && isDownloadMp3Enabled && downloadMp3 && DownloadMp3MenuItem}
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              active && (isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0',
                              !active && (isInstantListening || isItemOrSharePage()) && 'text-glass-700 dark:text-glass-0',
                              active && !(isInstantListening || isItemOrSharePage()) && 'bg-glass-200 text-glass-900',
                              !active && !(isInstantListening || isItemOrSharePage()) && 'text-glass-700',
                              'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                            )}
                            onClick={(e: MouseEvent) => handleActionClick(e, isFolder ? ItemActionType.ArchiveFolder : ItemActionType.Archive)}
                          >
                            <DeleteIcon className={twMerge((isInstantListening || isItemOrSharePage()) && 'dark:[&>path]:fill-glass-350')} aria-hidden="true" />
                            {t('Delete')}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  );
};

export default ItemActionMenu;
