import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserNameEnum, getBrowserName } from 'utils/browser';
import Arc from 'assets/icons/arc';
import Brave from 'assets/icons/brave';
import Edge from 'assets/icons/edge';
import Chrome from 'assets/icons/chrome';
import Opera from 'assets/icons/opera';

const getBrowserIcon = (browserName: BrowserNameEnum) => {
  if (browserName == BrowserNameEnum.Arc) return <Arc />;
  if (browserName == BrowserNameEnum.Brave) return <Brave />;
  if (browserName == BrowserNameEnum.MicrosoftEdge) return <Edge />;
  if (browserName == BrowserNameEnum.Opera) return <Opera />;
  return <Chrome />;
};

export const useBrowserInfo = () => {
  const [browserName, setBrowserName] = useState<string>('');
  const [browserIcon, setBrowserIcon] = useState<$TSFixMe>(null);

  useEffect(() => {
    const init = async () => {
      const browserName = await getBrowserName();
      const browserIcon = getBrowserIcon(browserName);
      setBrowserName(browserName);
      setBrowserIcon(browserIcon);
    };
    init();
  }, []);

  return {
    browserName,
    browserIcon
  };
};
