import React, { Fragment, useMemo } from 'react';

import CheckedIcon from 'assets/icons/checked';
import SortIcon from 'assets/icons/sort';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useRouter } from 'next/router';
import { useDispatch } from 'store';
import { actions as libraryActions } from 'store/library';
import { carryParams } from 'utils';

import { Menu, Transition } from '@headlessui/react';

const SortDisplayNamesToValue = {
  'Date added': 'added',
  'Last listened': 'opened',
  Name: 'alphabetical'
} as const;

type SortDisplay = keyof typeof SortDisplayNamesToValue;
type SortValue = (typeof SortDisplayNamesToValue)[SortDisplay];

const DEFAULT_SORT: SortValue = 'added';

function getCurrentSort(possibleSort: string | string[] | undefined): SortValue {
  if (typeof possibleSort === 'string' && Object.values<string>(SortDisplayNamesToValue).includes(possibleSort)) {
    return possibleSort as SortValue;
  }

  return DEFAULT_SORT;
}

export const SortMenu = () => {
  const router = useRouter();
  const navigate = useNavigate();
  // ESLint: 't' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const currentSort = useMemo(() => getCurrentSort(router.query.sort), [router.query.sort]);
  const sortOptions = useMemo<SortDisplay[]>(() => ['Name', 'Last listened', 'Date added'], []);

  const handleSortSelect = (sortType: SortDisplay) => {
    const sort = SortDisplayNamesToValue[sortType].toLowerCase();
    navigate(carryParams('/', { sort }));
    dispatch(libraryActions.setItemSort(sort));
  };

  return (
    <Menu as="div" className="relative inline-block h-5 text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="cursor-pointer rounded hover:bg-glass-300">
              <SortIcon />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-40 mt-0 w-44 origin-top-right overflow-hidden rounded-md border border-glass-300 bg-white py-1 shadow-lg focus:outline-none"
            >
              <Menu.Item key="Sort by">
                <div className="flex w-full items-center gap-2 px-4 py-2 text-xs text-glass-400 ">
                  <div className="h-[12px] w-[12px]" />
                  Sort by
                </div>
              </Menu.Item>
              {sortOptions.map(sortType => (
                <Menu.Item key={sortType}>
                  {() => (
                    <button
                      className="group flex w-full items-center gap-2 px-4 py-2 text-sm text-glass-700 hover:bg-glass-200"
                      onClick={handleSortSelect.bind(null, sortType)}
                    >
                      {!currentSort && SortDisplayNamesToValue[sortType] === 'added' && <CheckedIcon />}
                      {currentSort === SortDisplayNamesToValue[sortType] ? <CheckedIcon /> : <div className="h-[12px] w-[12px]" />}
                      {sortType}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SortMenu;
