import React, { useEffect } from 'react';

import Button from 'components/elements/Button';
import { useTranslation } from 'hooks/useTypedTranslation';
import { SpeechifyListeningExperience } from 'components/experience/sdk/experience';

interface StillListeningModalProps {
  onOk: () => void;
  listeningExperience: React.MutableRefObject<SpeechifyListeningExperience | undefined>;
}

const StillListeningModal: React.FC<StillListeningModalProps> = ({ onOk, listeningExperience }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    listeningExperience.current?.pause();
    // ESLint: React Hook useEffect has a missing dependency: 'listeningExperience'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="absolute left-1/2 top-1/2 inline-block w-3/4 -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xs sm:align-middle">
          <div className="bg-white p-5 pl-2">
            <div className="sm:flex sm:items-start">
              <div className="ml-4 mt-3 text-left sm:text-left">
                <h3 className="text-md leading-6 text-gray-900">{t('Audio paused')}</h3>
                <h3 className="text-md leading-6 text-gray-900">{t('Continue listening?')}</h3>
              </div>
            </div>
          </div>

          <div className="mt-3 flex flex-row-reverse bg-gray-50 px-4 py-3 sm:px-6">
            <Button title={t('Resume')} onClick={onOk} type="button" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StillListeningModal;
