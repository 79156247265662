import React, { Fragment, useMemo } from 'react';

import { IRecord, ItemActionType } from 'interfaces';
import { isDescendantById, sortBy } from 'utils';
import TreeNode from './TreeNode';

interface TreeNodeProps {
  parentId: string | null;
  activeDropTargetId?: string;
  currentFolderId?: string;
  items: IRecord[];
  onAction: (folder: IRecord, actionType: ItemActionType) => void;
  onClick: (folderId: string) => void;
  maxDepth?: number;
  level?: number;
}

export const TreeNodes: React.FC<TreeNodeProps> = ({ parentId, items, currentFolderId, activeDropTargetId, onAction, onClick, maxDepth = 8, level = 0 }) => {
  const folders = useMemo(() => items.filter((folder: IRecord) => folder.parentFolderId === parentId).sort(sortBy('title')), [items, parentId]);
  const showChildren = level < maxDepth;

  return (
    <Fragment>
      {folders.map(folder => (
        <TreeNode
          id={folder.id}
          isActive={currentFolderId === folder.id}
          isChildActive={
            showChildren &&
            currentFolderId !== null &&
            currentFolderId !== folder.id &&
            isDescendantById(items, folder.id, currentFolderId, 'id', 'parentFolderId', maxDepth)
          }
          isActiveDropTarget={activeDropTargetId === folder.id}
          key={folder.id}
          onAction={onAction}
          onClick={onClick}
          title={folder.title}
          hasChildren={showChildren && items.some(child => child.parentFolderId === folder.id)}
          level={level}
        >
          {showChildren && (
            <TreeNodes
              items={items}
              parentId={folder.id}
              activeDropTargetId={activeDropTargetId}
              currentFolderId={currentFolderId}
              onAction={onAction}
              onClick={onClick}
              maxDepth={maxDepth}
              level={level + 1}
            />
          )}
        </TreeNode>
      ))}
    </Fragment>
  );
};
