import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import debounce from 'lodash/debounce';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { SearchIcon, XIcon } from '@heroicons/react/outline';

interface SearchProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  disabled?: boolean;
  disabledText?: string;
}

export function Search({ value, onChange, disabled, disabledText }: SearchProps) {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = useState(disabled ? '' : (value ?? ''));

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
    onChange(undefined);
  }, [onChange]);

  const handleSearchValueChange = useMemo(
    () =>
      debounce((search: string) => {
        onChange(search || undefined);
      }, 500),
    [onChange]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);

      if (value) {
        handleSearchValueChange(value);
      } else {
        handleSearchValueChange.flush();
        handleSearchClear();
      }
    },
    [handleSearchClear, handleSearchValueChange]
  );

  useEffect(() => {
    if (!value) {
      handleSearchValueChange.flush();
      setSearchValue('');
    }
  }, [handleSearchValueChange, value]);

  return (
    <div className="flex relative">
      <label
        className={twMerge(
          'rounded-[8px] bg-glass-0 relative w-96 transition-colors',
          'border border-glass-300',
          !disabled && 'hover:border-glass-hovered-300 focus-within:border-glass-pressed-300 hover:focus-within:border-glass-pressed-300',
          disabled && 'bg-glass-200'
        )}
        data-tip
        data-tooltip-id="search-disabled-tooltip"
      >
        <SearchIcon className="absolute h-6 w-6 top-2 left-3 -my-px text-glass-400" aria-hidden="true" />
        <input
          id="integrations-search"
          name="search"
          className={twMerge('block w-full rounded-lg border-none py-2 pr-9 pl-12 bg-transparent -mt-px -mb-px', 'placeholder:text-glass-400 text-glass-800')}
          type="text"
          placeholder={t('Search your Drive documents')}
          autoComplete="off"
          value={searchValue}
          onChange={handleSearchChange}
          disabled={disabled}
        />
        {!!searchValue && (
          <XIcon
            className={twMerge(
              'absolute right-4 top-1/2 -translate-y-1/2 float-right block h-5 w-5 cursor-pointer sm:h-6',
              'text-glass-500 hover:text-glass-800 transition-colors'
            )}
            aria-hidden="true"
            onClick={handleSearchClear}
          />
        )}
      </label>
      {disabled && disabledText && (
        <ReactTooltip id="search-disabled-tooltip" place="top" variant="dark" className="!p-2 !leading-4 !border-x-0 !border-t-0">
          <span className="text-xs text-glass-1 font-ABCDiatype">{disabledText}</span>
        </ReactTooltip>
      )}
    </div>
  );
}
