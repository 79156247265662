import React, { Fragment, useMemo, useRef, useState } from 'react';

import Button from 'components/elements/Button';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'hooks/useTypedTranslation';
import { UUID } from 'interfaces';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage } from 'utils';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

interface MoveToModalViewProps {
  title?: string;
  options: { id: string; title: string }[];
  onCancel: () => void;
  onSubmit: (parentFolderId: string) => void;
}

export const MoveToModalView: React.FC<MoveToModalViewProps> = ({ title, options, onCancel, onSubmit }) => {
  const { t } = useTranslation('common');
  const containerRef = useRef<HTMLDivElement>(null);

  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const [selectedId, setSelectedId] = useState<UUID>(undefined);

  const selected = useMemo(() => options.find(option => option.id === selectedId), [options, selectedId]);

  const itemOrSharePage = isItemOrSharePage();

  useOnClickOutside(containerRef, () => onCancel());

  const handleSubmit = () => {
    if (selectedId !== undefined) {
      onSubmit(selectedId);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="fixed inset-0 z-2000 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div
        className={twMerge(
          itemOrSharePage
            ? 'min-h-screen px-4 pb-20 pt-4 text-center sm:p-0'
            : 'flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'
        )}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span
          className={twMerge(itemOrSharePage ? 'inline-block h-screen align-middle' : 'hidden sm:inline-block sm:h-screen sm:align-middle')}
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          ref={containerRef}
          className={twMerge(
            itemOrSharePage
              ? 'my-8 inline-block w-full transform rounded-lg bg-glass-0 text-left align-middle shadow-xl transition-all dark:bg-glass-700 sm:max-w-lg'
              : 'inline-block transform rounded-lg bg-glass-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle'
          )}
        >
          <div className={twMerge('p-5', 'rounded-t-lg pl-2', itemOrSharePage ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0')}>
            <div className={twMerge(itemOrSharePage ? 'flex items-start' : 'sm:flex sm:items-start')}>
              <div className={twMerge(itemOrSharePage ? 'ml-4 mt-0 text-left' : 'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left')}>
                <h3 className={twMerge('text-lg', 'font-medium', 'leading-6', itemOrSharePage ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700')}>
                  {title || t('Select Destination Folder')}
                </h3>
              </div>
            </div>
          </div>

          <div className="w-full p-6 pt-2">
            <label htmlFor="name" className={twMerge('sr-only')}>
              {t('Folder')}
            </label>
            <Listbox value={selectedId} onChange={setSelectedId}>
              {({ open }) => (
                <Fragment>
                  <Listbox.Label
                    className={twMerge('block', 'text-sm', 'font-medium', itemOrSharePage ? 'text-glass-500 dark:text-glass-350' : 'text-glass-500')}
                  >
                    {t('Folder')}
                  </Listbox.Label>
                  <div className="relative mt-1">
                    <Listbox.Button
                      className={twMerge(
                        'relative',
                        'w-full',
                        'py-2',
                        'pl-3',
                        'pr-10',
                        'text-left',
                        'border',
                        itemOrSharePage ? 'border-glass-300 dark:border-glass-600' : 'border-glass-300',
                        'rounded-md',
                        'shadow-sm',
                        'cursor-default',
                        itemOrSharePage ? 'bg-glass-0 dark:bg-glass-800' : 'bg-glass-0',
                        'focus:outline-none',
                        'sm:text-sm'
                      )}
                    >
                      <span
                        className={twMerge(
                          !itemOrSharePage && selectedId !== undefined && 'text-glass-700',
                          !itemOrSharePage && selectedId === undefined && 'text-glass-400',
                          itemOrSharePage && selectedId !== undefined && 'text-glass-700 dark:text-glass-0',
                          itemOrSharePage && selectedId === undefined && 'text-glass-400',
                          'block',
                          'truncate'
                        )}
                      >
                        {selected?.title || (options.length > 0 ? t('Select destination folder') : t('No destination folders available'))}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon className="h-5 w-5 text-glass-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open && options.length > 0}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        static
                        className={twMerge(
                          'absolute',
                          'z-10',
                          'w-full',
                          'py-1',
                          'mt-1',
                          'overflow-y-scroll',
                          'text-base',
                          'rounded-md',
                          'shadow-lg',
                          'max-h-[12.75rem]',
                          itemOrSharePage ? 'bg-glass-0 dark:bg-glass-800' : 'bg-glass-0',
                          'ring-1',
                          itemOrSharePage ? 'ring-glass-300 dark:ring-glass-600' : 'ring-glass-300',
                          'ring-opacity-5',
                          'focus:outline-none',
                          'sm:text-sm'
                        )}
                      >
                        {options.map(option => (
                          <Listbox.Option
                            key={option.id}
                            className={({ active }) =>
                              twMerge(
                                !itemOrSharePage && active && 'bg-folder1 text-glass-0',
                                !itemOrSharePage && !active && 'text-glass-700',
                                itemOrSharePage && active && 'bg-folder1 text-glass-0 dark:bg-electric-300 dark:text-glass-700',
                                itemOrSharePage && !active && 'text-glass-700 dark:text-glass-0',
                                'relative',
                                'cursor-default',
                                'select-none',
                                'py-2',
                                'pl-3',
                                'pr-9'
                              )
                            }
                            value={option.id}
                          >
                            {({ selected, active }) => (
                              <Fragment>
                                <span className={twMerge(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option.title}</span>

                                {selected ? (
                                  <span className={twMerge(active ? 'text-white' : 'text-folder1', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </Fragment>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Fragment>
              )}
            </Listbox>
          </div>

          <div
            className={twMerge(
              itemOrSharePage
                ? 'mt-3 flex flex-row-reverse justify-end rounded-b-lg bg-gray-50 px-6 py-3 dark:bg-glass-800 sm:justify-start'
                : 'mt-3 rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
            )}
          >
            <Button
              className={twMerge(itemOrSharePage ? 'dark:border-transparent dark:bg-electric-300 dark:text-glass-700 dark:disabled:bg-opacity-80' : '')}
              disabled={!selected}
              onClick={handleSubmit}
              title={t('Move')}
            />
            <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveToModalView;
