import React, { useCallback, useState } from 'react';

import { Spinner } from 'assets/images';
import { useTranslation } from 'hooks/useTypedTranslation';
import { twMerge } from 'tailwind-merge';

import { useImportFile } from './hooks/useImportFile';

export function TextImport() {
  const { t } = useTranslation('common');
  const importText = useImportFile();

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [isImporting, setIsImporting] = useState(false);

  const handleImportText = useCallback(async () => {
    setIsImporting(true);
    await importText({ data: { title, text } });
    setIsImporting(false);
  }, [importText, text, title]);

  const disabled = !text;

  return (
    <div className="py-5 px-14 flex flex-col gap-4 w-full h-full text-glass-700 overflow-y-auto">
      <div className="flex flex-col gap-1 w-full">
        <label htmlFor="textImportTitle" className="font-medium">
          {t('Title')}
        </label>
        <input
          id="textImportTitle"
          className={twMerge(
            'h-10 px-3 rounded-md transition-colors',
            'placeholder:text-glass-400 border border-px border-glass-300',
            'hover:border-glass-hovered-300 focus:border-electric-pressed-350'
          )}
          style={{ boxShadow: 'none' }}
          autoComplete="off"
          placeholder={t('Optional')}
          value={title}
          onChange={e => setTitle(e.target.value)}
          autoFocus
          disabled={isImporting}
        />
      </div>
      <div className="flex flex-col gap-1 w-full h-full">
        <label htmlFor="textImportText" className="font-medium">
          {t('Text')}
        </label>
        <textarea
          id="textImportText"
          className={twMerge(
            'h-10 px-3 h-full rounded-md transition-colors',
            'placeholder:text-glass-400 border border-px border-glass-300',
            'hover:border-glass-hovered-300 focus:border-electric-pressed-350'
          )}
          placeholder={t('Type or Paste Text')}
          value={text}
          onChange={e => setText(e.target.value)}
          disabled={isImporting}
        />
      </div>
      <div className="flex gap-1 w-full">
        <button
          className={twMerge(
            'flex items-center gap-2',
            'py-2.5 px-5 ml-auto rounded-[8px] transition-colors text-base font-bold',
            disabled ? 'text-[#B3BDCA] bg-[#E4E7EC]' : 'text-glass-0 bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
          )}
          disabled={isImporting || disabled}
          onClick={handleImportText}
        >
          {isImporting && <Spinner width="20" fill="white" className="animate-spin" />}
          {t('Add Text')}
        </button>
      </div>
    </div>
  );
}
