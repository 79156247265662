import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Cross } from 'assets/icons/cross';
import FolderMoveIcon from 'assets/icons/folder-move';
import InsideRight from 'assets/icons/inside';
import MoreHorizontal from 'assets/icons/more-horizontal';
import OutlinedFolder from 'assets/icons/outlined-folder';
import SyncIcon from 'assets/icons/sync';
import TrashIcon from 'assets/icons/trash';
import UpToDateTick from 'assets/icons/upToDateTick';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IRecord, ItemActionType, ItemGroupActionType, ViewType } from 'interfaces';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { Menu } from '@headlessui/react';

import ItemActionMenu from './ActionMenu';
import ItemViewMenu from './components/ItemViewMenu';
import FilterMenu from './FilterMenu';
import SortMenu from './SortMenu';

interface HeaderProps {
  folderId: string;
  folders: IRecord[];
  isSkeletonVisible: boolean;
  isSyncing: boolean;
  selectedItemsCount: number;
  selectedFoldersCount: number;
  handleFolderClick: (folderId?: string | null) => void;
  onItemAction: (item: IRecord, actionType: ItemActionType, location?: string) => void;
  onSelectedItemsAction: (actionType: ItemGroupActionType) => void;
  onUnselectAll: () => void;
  setViewType: (viewType: ViewType) => void;
  viewType: ViewType;
}

export const Header = ({
  folderId,
  folders,
  handleFolderClick,
  isSkeletonVisible,
  isSyncing,
  onItemAction,
  selectedItemsCount,
  selectedFoldersCount,
  setViewType,
  viewType,
  onSelectedItemsAction,
  onUnselectAll
}: HeaderProps) => {
  const { t } = useTranslation('common');

  const [showIsUpToDate, setShowIsUpToDate] = useState(false);
  const [wasSyncing, setWasSyncing] = useState(false);

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isUpToDateTimerRef = useRef<any>(null);

  useEffect(() => {
    if (isSkeletonVisible) return;

    if (wasSyncing && !isSyncing) {
      setShowIsUpToDate(true);
    }

    if (isSyncing) setWasSyncing(true);
    else setWasSyncing(false);
    // ESLint: React Hook useEffect has a missing dependency: 'wasSyncing'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkeletonVisible, isSyncing]);

  useEffect(() => {
    if (showIsUpToDate) {
      isUpToDateTimerRef.current = setTimeout(() => {
        setShowIsUpToDate(false);
      }, 2000);
    }

    return () => {
      if (isUpToDateTimerRef.current) {
        clearTimeout(isUpToDateTimerRef.current);
      }
    };
  }, [showIsUpToDate]);

  const getFolderById = (folderId: string) => {
    return folders.find((item: IRecord) => item.id === folderId);
  };

  const getFolderParents = (folder: IRecord) => {
    const parents = [];
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    let parent = getFolderById(folder?.parentFolderId);
    while (parent) {
      parents.push(parent);
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      parent = getFolderById(parent.parentFolderId);
    }
    return parents.reverse();
  };

  const mainFolder = folderId && getFolderById(folderId);
  // @ts-expect-error TS(2345): Argument of type '"" | IRecord | undefined' is not... Remove this comment to see the full error message
  const parentFolders = getFolderParents(mainFolder);

  const handleFolderAction = (actionType: ItemActionType) => {
    // @ts-expect-error TS(2345): Argument of type '"" | IRecord | undefined' is not... Remove this comment to see the full error message
    onItemAction(mainFolder, actionType);
  };

  const handleSelectedItemsMove = () => {
    onSelectedItemsAction(ItemGroupActionType.Move);
  };

  const handleSelectedItemsDelete = () => {
    onSelectedItemsAction(ItemGroupActionType.Delete);
  };

  const isTrash = folderId === 'trash';

  const selectedActions = [
    {
      label: t('Move'),
      icon: <FolderMoveIcon />,
      action: handleSelectedItemsMove
    },
    {
      label: t('Delete'),
      icon: <TrashIcon />,
      action: handleSelectedItemsDelete
    }
  ];

  const selectedText = useMemo(() => {
    const files = t('files', { count: selectedItemsCount });
    const folders = t('folders', { count: selectedFoldersCount });

    if (selectedItemsCount && selectedFoldersCount) {
      return t('Files and folders selected', { files, folders });
    }

    if (selectedItemsCount) {
      return t('Files selected', { files });
    }

    if (selectedFoldersCount) {
      return t('Folders selected', { folders });
    }

    return '';
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsCount, selectedFoldersCount]);

  return (
    <div className={twMerge('sticky top-16 z-[30] bg-white', 'flex justify-between', 'border-b border-glass-300 px-5 py-4 border-t')}>
      <div className="flex flex-wrap items-center">
        {selectedItemsCount > 0 || selectedFoldersCount > 0 ? (
          <div className="flex items-center gap-5 font-ABCDiatype">
            <button className="h-6 w-6 rounded-md p-0.5 hover:bg-glass-300" onClick={onUnselectAll} data-tip={true} data-tooltip-id="tooltip-unselect-all">
              <Cross className="block h-5 w-5 text-secondaryTint" />
            </button>
            <div className="text-xl font-bold leading-7 text-glass-700">{selectedText}</div>
            <div className="h-7 w-[1px] bg-glass-300" />
            <div className="flex gap-3">
              {selectedActions.map(({ label, action, icon }) => (
                <button
                  key={label}
                  className={twMerge(
                    'flex items-center gap-1.5 px-3 py-[3px]',
                    'rounded-md border border-glass-300 hover:border-buttonHover active:border-buttonHover',
                    'text-sm font-medium leading-5 text-glass-500'
                  )}
                  onClick={action}
                >
                  {icon}
                  {label}
                </button>
              ))}
            </div>
            {createPortal(
              <ReactTooltip id="tooltip-unselect-all" place="bottom" variant="dark" className="!p-2 bg-[#14202E] text-[#FCFEFE]">
                <span className="text-xs leading-4">{t('Cancel selection')}</span>
              </ReactTooltip>,
              document.body
            )}
          </div>
        ) : (
          <>
            {isTrash ? (
              <div className="flex-none font-ABCDiatype text-xl font-bold text-glass-700">{t('Trash')}</div>
            ) : parentFolders.length > 1 ? (
              <Menu as={'div'} className={'relative'}>
                <Menu.Button className={'flex items-center hover:rounded-full hover:bg-[#DEE5EB] '}>
                  <MoreHorizontal />
                </Menu.Button>
                <Menu.Items className="absolute left-0 mt-2 w-80 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {[{ id: null, title: t('My Library') }, ...parentFolders.slice(0, parentFolders.length - 1)].map(parentFolder => (
                    // Missing "key" prop for element in iteratoreslintreact/jsx-key
                    // eslint-disable-next-line react/jsx-key
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={twMerge(`${active && 'bg-glass-200'}`, 'flex gap-3 items-center cursor-pointer px-4 py-3')}
                          onClick={() => handleFolderClick(parentFolder.id)}
                        >
                          <div>
                            <OutlinedFolder className="w-full h-full" />
                          </div>
                          <div className="line-clamp-1">{parentFolder.title}</div>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            ) : (
              <div
                onClick={() => mainFolder && handleFolderClick()}
                className={twMerge(mainFolder ? 'cursor-pointer text-glass-500' : 'text-glass-700', 'flex-none font-ABCDiatype text-xl font-bold')}
              >
                {t('My Library')}
              </div>
            )}
            {parentFolders.length > 0 && (
              <>
                {parentFolders.slice(-1).map(parentFolder => (
                  <div key={parentFolder.id} className="flex cursor-pointer items-center" onClick={() => handleFolderClick(parentFolder.id)}>
                    <InsideRight className="mx-2" />
                    <div className="max-w-xs flex-none truncate font-ABCDiatype text-xl font-medium text-glass-500">{parentFolder.title}</div>
                  </div>
                ))}
              </>
            )}
            {mainFolder && (
              <div className="ml-2 flex items-center gap-2">
                <InsideRight />
                <div className="max-w-xs flex-none truncate font-ABCDiatype text-xl font-bold text-glass-700">{mainFolder.title}</div>
                <ItemActionMenu arrowIcons={true} isFolder={true} onAction={handleFolderAction} />
              </div>
            )}
          </>
        )}
      </div>
      {isSyncing && !isSkeletonVisible && (
        <div className="flex items-center gap-2">
          <SyncIcon className="w-5 h-5 animate-spin-slow" />
          <div className="font-ABCDiatype text-base font-medium text-electric-400">{t('Syncing')}</div>
        </div>
      )}
      {!isSyncing && showIsUpToDate && !isSkeletonVisible && (
        <div className="flex items-center gap-2">
          <UpToDateTick className="w-5 h-5" />
          <div className="font-ABCDiatype text-base font-medium text-green-400">{t('Up to date')}</div>
        </div>
      )}
      {((!isSyncing && !showIsUpToDate) || isSkeletonVisible) && selectedItemsCount === 0 && selectedFoldersCount === 0 && (
        <div className="flex items-center gap-2">
          {!isTrash && <SortMenu />}
          {!isTrash && <FilterMenu />}
          <ItemViewMenu setViewType={setViewType} viewType={viewType} />
        </div>
      )}
    </div>
  );
};

export default Header;
