import { ALLOWED_MIME_TYPES } from 'interfaces';
import { IntegrationFile, IntegrationService } from 'interfaces/integrations';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { actions as integrationActions, selectors as integrationSelectors } from 'store/integration';
import { getCustomAccountSetting, setCustomAccountSetting } from 'utils/baseAccountSettings';
import { downloadFile, fetchFile } from '../api';
import { INTEGRATION_FILES_SETTINGS_KEY } from '../constants';
import { useImportFile } from './useImportFile';
import { useListenFile } from './useListenFile';

type FileState = 'not-imported' | 'importing' | 'imported';
type FileAction = () => Promise<void>;

export function useFileAction(service: IntegrationService, file: IntegrationFile): [FileState, FileAction] {
  const dispatch = useDispatch();
  const importFile = useImportFile();
  const listenFile = useListenFile();

  const [isImporting, setIsImporting] = useState(false);
  const itemId = useSelector(integrationSelectors.getImportedItemId(service, file));
  const mimeType = file.mimeType === ALLOWED_MIME_TYPES.GDOC ? ALLOWED_MIME_TYPES.PDF : file.mimeType;

  const getFileUrl = useCallback(async (): Promise<string | null> => {
    if (!file.actions.signed) return null;

    try {
      const {
        actions: { signed }
      } = await fetchFile(service, file.id);
      return new URL(signed ?? file.actions.signed, process.env.NEXT_PUBLIC_INTEGRATIONS_BASEURL).toString();
    } catch {
      return null;
    }
  }, [file.actions.signed, file.id, service]);

  const getFileBlob = useCallback(
    async (fileName: string) => {
      const blob = await downloadFile(file.actions.download);
      return new File([blob], fileName, { type: mimeType });
    },
    [file, mimeType]
  );

  const handleFileImport = useCallback(async () => {
    setIsImporting(true);

    const source = service
      .split(/[_-]/)
      .map(([first, ...other]) => `${first.toUpperCase()}${other.join('')}`)
      .join('');
    const itemId = await importFile({ data: file, source, getFileUrl, getFileBlob });

    setIsImporting(false);

    if (itemId) {
      let ids: string[];
      try {
        const idsResponse = await getCustomAccountSetting(INTEGRATION_FILES_SETTINGS_KEY[service]);
        ids = JSON.parse(idsResponse as string).ids as string[];
      } catch {
        ids = [];
      }
      ids.push(file.id);
      await setCustomAccountSetting(INTEGRATION_FILES_SETTINGS_KEY[service], JSON.stringify({ ids: [file.id] }));

      dispatch(integrationActions.setFileImported({ service, file, itemId }));
      listenFile(itemId, mimeType, true);
    }
  }, [dispatch, service, file, mimeType, importFile, listenFile, getFileUrl, getFileBlob]);

  const handleFileListen = useCallback(() => {
    if (itemId) {
      listenFile(itemId, mimeType);
    }
  }, [listenFile, mimeType, itemId]);

  const handleFileAction = useCallback(async () => {
    if (isImporting || !file.importable) return;
    if (itemId) return handleFileListen();

    return handleFileImport();
  }, [handleFileImport, handleFileListen, isImporting, itemId, file.importable]);

  const fileState: FileState = itemId ? 'imported' : isImporting ? 'importing' : 'not-imported';

  return [fileState, handleFileAction];
}
