import React, { ChangeEvent, FocusEvent, Fragment, KeyboardEventHandler, MouseEvent, useEffect, useState } from 'react';

import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ISearchResult } from 'interfaces';
import { debounce } from 'lodash';
import { RootState, useDispatch, useSelector } from 'store';
import { actions as libraryActions } from 'store/library';
import { carryParams } from 'utils';

import { Menu, Transition } from '@headlessui/react';
import { DocumentIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';

const debouncedSearch = debounce((dispatch, setSearching, query) => {
  setSearching(true);
  dispatch(libraryActions.searchArticles(query));

  setTimeout(() => {
    setSearching(false);
  }, 5000);
}, 500);

export const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  // state
  const [isOpen, setIsOpen] = useState(false);
  const [searching, setSearching] = useState(false);

  // search query and results
  const { searchResults, searchQuery } = useSelector((state: RootState) => state.library);

  useEffect(() => {
    dispatch(libraryActions.setSearchQuery(''));
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [searchQuery]);

  const handleItemClick = (id: string, e: MouseEvent) => {
    if (e.metaKey) {
      window.open(carryParams(`/item/${id}`), '_blank');
    } else {
      navigate(carryParams(`/item/${id}`));
    }
  };

  // ESLint: 'e' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSearchBlur = (e: FocusEvent) => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      debouncedSearch(dispatch, setSearching, searchQuery);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(libraryActions.setSearchQuery(e.target.value));
    debouncedSearch(dispatch, setSearching, e.target.value);
  };

  // ESLint: 'e' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSearchFocus = (e: FocusEvent) => {
    if (searchQuery) {
      setIsOpen(true);
    }
  };

  return (
    <div className="min-w-0 flex-1 px-0 ">
      <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
        <div className="w-full max-w-4xl">
          <form>
            <label htmlFor="search" className="sr-only">
              {t('Search')}
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon className="h-5 w-5 text-glass-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full rounded-md border-none bg-glass-200 py-2 pl-10 pr-3 text-sm focus:text-gray-900 sm:text-sm"
                type="search"
                placeholder={t('Search')}
                onBlur={handleSearchBlur}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
                onKeyDown={handleSearchKeyDown}
                value={searchQuery}
              />
            </div>
          </form>

          <Menu as="div" className="relative h-0 text-left">
            <Transition
              show={isOpen}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 z-10 mt-1 w-full origin-top-right divide-y divide-gray-200 rounded-lg bg-gray-50 focus:outline-none"
              >
                {searching && searchResults.length === 0 && <p className="p-4 text-sm text-gray-500">{t('Searching')}</p>}
                {searchResults.map((searchResult: ISearchResult) => {
                  return (
                    <div
                      key={searchResult.id}
                      className="flex cursor-pointer select-none p-4 hover:bg-gray-50"
                      onClick={handleItemClick.bind(null, searchResult.id)}
                    >
                      <div
                        className="bg-gray-200', 'h-12 w-12 rounded-md bg-opacity-75 bg-cover hover:bg-opacity-100"
                        style={searchResult.coverImageUrl ? { backgroundImage: `url(${searchResult.coverImageUrl})` } : {}}
                      >
                        {!searchResult.coverImageUrl && (
                          <div className="flex items-center justify-center pt-3">
                            <DocumentIcon className="h-6 w-6 text-gray-300 opacity-60" />
                          </div>
                        )}
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{searchResult.title}</p>
                      </div>
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
