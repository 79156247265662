import React from 'react';
import { useTranslation } from 'hooks/useTypedTranslation';
import { SpeechifyLogo } from 'assets/icons/speechify-logo';
import { QRCodeForGetApp } from 'assets/images/qrCodeForGetApp';

export function ScanImport() {
  const { t } = useTranslation('common');

  return (
    <div className="flex justify-center w-full h-full text-center overflow-y-auto">
      <div className="flex flex-col gap-4 items-center p-5 my-auto">
        <div
          className="w-20 h-20 flex items-center justify-center rounded-[16px] overflow-hidden p-[13px] shrink-0"
          style={{
            backgroundImage: 'linear-gradient(144.21deg, #2137FC 0%, #1608B8 88.31%)'
          }}
        >
          <SpeechifyLogo className="drop-shadow-[0px_4px_4px_#0000003D] text-glass-0" />
        </div>
        <div className="flex flex-col gap-1 max-w-[400px]">
          <h3 className="text-2xl text-glass-700 font-bold">{t('Use Speechify mobile app to scan any book or document')}</h3>
          <p className="text-glass-500">{t('Please scan the QR code below to download the app.')}</p>
        </div>
        <div className="shrink-0 p-5 rounded-[20px] bg-glass-200">
          <QRCodeForGetApp className="w-50 h-50" />
        </div>
      </div>
    </div>
  );
}
