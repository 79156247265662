import { useNavigate } from 'hooks/useNavigate';
import { useCallback, useRef } from 'react';
import { carryParams } from 'utils';
import { setTimeToPlay } from 'utils/analytics';
import { useDispatch, useSelector } from 'store';
import { actions as mainPageActions } from 'store/mainPage';
import { actions as importActions } from 'store/import';
import { getRecordType } from '../utils';

export function useListenFile(): (itemId: string, mimeType: string, isJustImported?: boolean) => void {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instantListening } = useSelector(state => state.mainPage);
  const instantListeningRef = useRef(instantListening);

  const handleListenClick = useCallback(
    (itemId: string, mimeType: string, isJustImported?: boolean) => {
      dispatch(importActions.closeImportDialog());

      if (!isJustImported) {
        setTimeToPlay('open-item', getRecordType(mimeType));
        navigate(carryParams(`/item/${itemId}`));
      } else {
        if (instantListeningRef.current) {
          history.pushState({}, '', `/item/${itemId}`);
        }

        dispatch(mainPageActions.setNewlyImportedItemId(itemId));
      }
    },
    [dispatch, navigate]
  );

  return handleListenClick;
}
