import React, { useCallback } from 'react';
import { IntegrationService } from 'interfaces/integrations';
import { IntegrationIcons, IntegrationTitles } from './constants';
import { Spinner } from 'assets/images';
import { actions as importActions } from 'store/import';
import { useDispatch } from 'react-redux';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';
import { ImportSourceCard } from './ImportSourceCard';

export function IntegrationImportCard({ service }: { service: IntegrationService }) {
  const dispatch = useDispatch();
  const [authorizeData, isAuthorizing, authorize] = useIntegrationAuth(service);

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (authorizeData.authorized || isAuthorizing) return;

      e.stopPropagation();

      const authorized = await authorize();

      if (authorized) {
        dispatch(importActions.openImportDialog({ source: service }));
      }
    },
    [service, authorize, authorizeData.authorized, isAuthorizing, dispatch]
  );

  return (
    <ImportSourceCard
      icon={IntegrationIcons[service]}
      title={IntegrationTitles[service].name}
      buttonType={authorizeData.authorized ? 'outline' : 'solid'}
      buttonText={
        authorizeData.authorized ? 'View Files' : isAuthorizing ? <Spinner width="20" fill="white" className="animate-spin inline-block" /> : 'Connect'
      }
      onClick={!authorizeData.authorized && !isAuthorizing ? handleClick : undefined}
    />
  );
}
