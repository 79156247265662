import React, { useEffect, useRef } from 'react';

import Button from 'components/elements/Button';
import TextInput from 'components/elements/TextInput';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IItemState, ItemType } from 'interfaces';
import { useForm } from 'react-hook-form';
import { RootState, useDispatch, useSelector } from 'store';
import { actions as itemActions } from 'store/item';
import { actions as libraryActions } from 'store/library';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage } from 'utils';
import { DOCUMENT_TITLE_MAX_LENGTH } from 'utils/constants';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

export const RenameModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const containerRef = useRef<HTMLDivElement>(null);

  const { itemToRename, renameTitleOverride } = useSelector<IItemState>((state: RootState) => state.item);

  const schema = yup.object().shape({
    name: yup.string().required(t('A valid name is required'))
  });

  const {
    formState: { errors },
    handleSubmit: handleSubmitWrapper,
    register,
    setFocus,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus('name');
    }, 250);
  }, [setFocus]);

  useEffect(() => {
    reset({
      name: renameTitleOverride || itemToRename?.title || ''
    });
    // ESLint: React Hook useEffect has a missing dependency: 'reset'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameTitleOverride, itemToRename]);

  const handleSubmit = (data: { name: string }) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    dispatch(libraryActions.setName({ itemId: itemToRename.id, name: data.name, isFolder: itemToRename.type === ItemType.Folder }));
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToRename(undefined));
  };

  const handleCancel = () => {
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToRename(undefined));
  };

  useOnClickOutside(containerRef, () => handleCancel());

  return itemToRename ? (
    <div className="fixed inset-0 z-2000 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div
        className={twMerge(
          isItemOrSharePage()
            ? 'min-h-screen px-4 pb-20 pt-4 text-center sm:p-0'
            : 'flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'
        )}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span
          className={twMerge(isItemOrSharePage() ? 'inline-block h-screen align-middle' : 'hidden sm:inline-block sm:h-screen sm:align-middle')}
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          ref={containerRef}
          className={twMerge(
            isItemOrSharePage()
              ? 'my-8 inline-block w-full transform overflow-hidden rounded-lg bg-glass-0 text-left align-middle shadow-xl transition-all dark:bg-glass-700 sm:max-w-lg'
              : 'inline-block transform overflow-hidden rounded-lg bg-glass-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle'
          )}
        >
          <div className={twMerge('p-5', 'pl-2', isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0')}>
            <div className={twMerge(isItemOrSharePage() ? 'flex items-start' : 'sm:flex sm:items-start')}>
              <div className={twMerge(isItemOrSharePage() ? 'ml-4 mt-0 text-left' : 'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left')}>
                <h3 className={twMerge('text-lg', 'font-medium', 'leading-6', isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700')}>
                  {t('Rename Item')}
                </h3>
              </div>
            </div>
          </div>

          <form
            className="m-0"
            // @ts-expect-error TS(2345): Argument of type '(data: {    name: string;}) => v... Remove this comment to see the full error message
            onSubmit={handleSubmitWrapper(handleSubmit)}
          >
            <div className="w-full p-6 pt-2">
              <label htmlFor="name" className="sr-only">
                {t('Item name')}
              </label>
              <TextInput
                className={twMerge(
                  isItemOrSharePage() ? 'dark:border dark:border-solid dark:border-glass-600 dark:bg-glass-800 dark:text-glass-0' : '',
                  'speechify-ignore-keybindings'
                )}
                error={errors.name}
                maxLength={DOCUMENT_TITLE_MAX_LENGTH}
                // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                name="name"
                placeholder={t('Item name')}
                {...register('name')}
              />
            </div>

            <div
              className={twMerge(
                isItemOrSharePage()
                  ? 'mt-3 flex flex-row-reverse justify-end bg-gray-50 px-6 py-3 dark:bg-glass-800 sm:justify-start'
                  : 'mt-3 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
              )}
            >
              <Button
                title={t('Submit')}
                type="submit"
                className={twMerge(isItemOrSharePage() ? 'dark:border-transparent dark:bg-electric-300 dark:text-glass-700' : '')}
              />
              <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : null;
};

export default RenameModal;
