import * as React from 'react';
import { useEffect, useState } from 'react';

import { IItemState, IUser } from 'interfaces';
import * as speechify from 'lib/speechify';
import { useDispatch, useSelector } from 'store';
import { actions as itemActions } from 'store/item';

import Download from '../downloads/Download';
import DownloadChoiceOnItem from '../downloads/DownloadChoiceOnItem';
import DownloadUpsellForFreeUsers from '../downloads/UpsellForFreeUsers';
import DownloadUpsellForTrialUsers from '../downloads/UpsellForTrialUsers';

export const ItemDownload: React.FC = () => {
  const dispatch = useDispatch();
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);
  const { itemToDownload } = useSelector<IItemState>(state => state.item);

  const [showDownloadChoice, setShowDownloadChoice] = useState<boolean>(false);
  const [showFreeUserDownloadUpsell, setShowFreeUserDownloadUpsell] = useState(false);
  const [showTrialUserDownloadUpsell, setShowTrialUserDownloadUpsell] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const handleDownloadChoiceClose = () => {
    setShowDownloadChoice(false);
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToDownload(undefined));
  };

  const handleDownloadChoiceSubmit = () => {
    setShowDownloadChoice(false);
    // show upsells
    const isTrial = speechify.isOnTrial(currentUser);
    const isPremium = speechify.isPremium(currentUser);

    const isFreeUser = !isTrial && !isPremium;
    const isTrialUser = isTrial;
    const isPremiumUser = (!isTrial && isPremium) || true; // todo: fix

    if (isFreeUser) {
      setShowFreeUserDownloadUpsell(true);
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      dispatch(itemActions.setItemToDownload(undefined));
      dispatch;
    } else if (isTrialUser) {
      setShowTrialUserDownloadUpsell(true);
    } else if (isPremiumUser) {
      // we can show the download ui
      setShowDownload(true);
    }
  };

  const handleDownloadFreeUserUpsellClose = () => {
    setShowFreeUserDownloadUpsell(false);
  };

  const handleDownloadTrialUserUpsellClose = () => {
    setShowTrialUserDownloadUpsell(false);
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToDownload(undefined));
  };

  const handleOpenDownloadMp3AfterTrialUpsell = () => {
    setShowTrialUserDownloadUpsell(false);
    setShowDownload(true);
  };

  const handleDownloadMp3Close = () => {
    setShowDownload(false);
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToDownload(undefined));
  };

  useEffect(() => {
    setShowDownloadChoice(Boolean(itemToDownload));
  }, [itemToDownload]);

  return (
    <>
      <DownloadChoiceOnItem open={showDownloadChoice} onOpenDownloadMp3={handleDownloadChoiceSubmit} onClose={handleDownloadChoiceClose} />
      <DownloadUpsellForFreeUsers open={showFreeUserDownloadUpsell} onClose={handleDownloadFreeUserUpsellClose} />
      {itemToDownload && (
        <DownloadUpsellForTrialUsers
          open={showTrialUserDownloadUpsell}
          onClose={handleDownloadTrialUserUpsellClose}
          onOpenDownload={handleOpenDownloadMp3AfterTrialUpsell}
        />
      )}
      {itemToDownload && <Download open={showDownload} itemId={itemToDownload.id} onClose={handleDownloadMp3Close} />}
    </>
  );
};

export default ItemDownload;
