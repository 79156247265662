import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import BackIcon from 'assets/icons/back';
import ReportIcon from 'assets/icons/report';
import ShareIcon from 'assets/icons/sharelarge';
import SupportIcon from 'assets/icons/support';
import { Logo } from 'assets/images';
import { ItemActionMenu, MoveToModal, RenameModal, ShareModal } from 'components/library';
import { ContactSupportAlertBanner } from 'components/settings/ContactSupportAlertBanner';
import SupportMenu from 'components/sidebar/controls/SupportMenu';
import { ErrorSource } from 'constants/errors';
import { FeatureNameEnum, useFeatureFlag } from 'hooks/useFeatureFlags';
import useMediaQuery from 'hooks/useMediaQuery';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IRecord, ItemActionType, RecordType } from 'interfaces';
import { IUser } from 'interfaces/user';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { RootState, useDispatch, useSelector } from 'store';
import { setUser } from 'store/auth/actions';
import { isNewUserSince } from 'store/auth/helpers';
import { selectors as librarySelectors } from 'store/library';
import { actions as sidebarActions, selectors as sidebarSelectors, SidebarTabs } from 'store/sidebar';
import { actions as toastActions } from 'store/toast';
import { twMerge } from 'tailwind-merge';
import { carryParams, doItemsMatch, isItemOrSharePage, isItemPage } from 'utils';
import { getSource, logSegmentEvent } from 'utils/analytics';

import { Popover } from '@headlessui/react';

import { actions as itemActions } from '../../store/item';
import ConfirmItemActionModal from './ConfirmItemActionModal';
import ConfirmPersonalVoiceActionModal from './ConfirmPersonalVoiceActionModal';
import HeaderAvatar from './HeaderAvatar';
import ItemDownload from './ItemDownload';
import SearchBar from './SearchBar';

const StreakCounter = dynamic(() => import('../gamification/StreakCounter'), { ssr: false });

const Back = () => {
  const router = useRouter();
  const navigate = useNavigate();

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackClick = (event: any) => {
    logSegmentEvent('web_app_home_button_pressed', { source: getSource(router.pathname) });
    event.preventDefault();
    navigate(carryParams('/'));
  };

  return (
    <a href="/" className="flex h-5 w-5 items-center justify-center" onClick={handleBackClick}>
      <BackIcon className="h-5 w-5 [&>path]:fill-glass-700 dark:[&>path]:fill-glass-0" />
    </a>
  );
};

// ESLint: 'ReportBtn' is assigned a value but never used & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const ReportBtn = ({ onClick }: any) => {
  const { t } = useTranslation('common');

  return (
    <div className="ml-4 hidden flex-shrink-0 cursor-pointer select-none items-center text-glass-500 md:flex" onClick={() => onClick()}>
      <ReportIcon />
      <span className="ml-1 font-ABCDiatype text-sm font-medium sm:text-base">{t('Report')}</span>
    </div>
  );
};

export const Home = () => {
  const router = useRouter();
  const navigate = useNavigate();

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    event.preventDefault();

    navigate('/');

    logSegmentEvent('web_app_logo_pressed', { source: getSource(router.pathname) });
  };

  return (
    <div className="flex flex-shrink-0 items-center">
      <a className="text-speechify-blue-600 hover:text-speechify-blue-700" href="/" onClick={handleClick}>
        <Logo aria-hidden="true" />
      </a>
    </div>
  );
};

const TitleBar = ({ title }: { title: string }) => {
  const maxLength = 60;

  return (
    <div className="mt-2 min-w-0 flex-1 px-2 md:px-8 lg:px-0 xl:col-span-7">
      <div className="flex items-center justify-center truncate px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
        <h3 className="text-sm font-bold text-glass-700 md:text-base ">
          &nbsp;{title && title.length > maxLength ? title.substr(0, maxLength) + '...' : title}&nbsp;
        </h3>
      </div>
    </div>
  );
};

interface HeaderProps {
  avatar?: boolean;
  back?: boolean;
  fixed?: boolean;
  newlyImportedItemId?: string;
  search?: boolean;
  title?: string;
  showContactSupportBanner?: boolean;
  onClickReportBtn?: () => void;
  isInstantListening?: boolean;
}

export const Header: React.FC<HeaderProps> = props => {
  const { avatar = true, back, newlyImportedItemId, search = true, title, showContactSupportBanner, isInstantListening } = props;
  const [hideControls, setHideControls] = useState(false);

  const { t } = useTranslation('common');
  const router = useRouter();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemId = newlyImportedItemId || (router.query.id as string);

  const item: IRecord = useSelector(state => librarySelectors.getById(itemId)(state), doItemsMatch);

  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);
  const subscription = useSelector(state => state.auth.user?.subscription);
  const isSidebarLoading = useSelector(sidebarSelectors.getIsSidebarLoading());
  const { folders } = useSelector((state: RootState) => state.library);

  const supportButtonRef = useRef<HTMLButtonElement>(null);
  const [titleContainerDiv, setTitleContainerDiv] = useState<HTMLDivElement | null>(null);
  const [titleRectWidth, setTitleRectWidth] = useState<number>(0);
  const [shareProps, setShareProps] = useState<{ loading?: boolean; url?: string }>({});
  const [isSupportMenuOpen, setIsSupportMenuOpen] = useState<boolean>(false);
  const [canResumeCancelledSubscription, setCanResumeCancelledSubscription] = useState(false);

  const { matches: isTablet } = useMediaQuery('(min-width: 768px)');

  const isDownloadEnabled = speechify.isStripe(currentUser) && !speechify.isOnTrial(currentUser) && !isNewUserSince(currentUser);

  const downloadMp3 = useMemo(() => isDownloadEnabled && item.recordType === RecordType.PDF, [item, isDownloadEnabled]);

  const isPremium = speechify.isPremium(currentUser) && !speechify.isOnTrial(currentUser);

  // feature flags
  const { variant: replaceEndShareVariant, isLoading: replaceEndShareVariantLoading } = useFeatureFlag(FeatureNameEnum.REPLACE_END_SHARE);

  useEffect(() => {
    setHideControls(localStorage.getItem('hidecontrols') === 'true');

    window.addEventListener('preference', () => {
      setHideControls(localStorage.getItem('hidecontrols') === 'true');
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      if (speechify.isStripe(currentUser) && speechify.isCanceled(currentUser)) {
        const willChargeUser = await speechify.willChargeUserForRenewal();

        if (!willChargeUser) {
          setCanResumeCancelledSubscription(true);
        }
      }
    };

    setCanResumeCancelledSubscription(false);

    if (currentUser?.uid) {
      init();
    }
    // ESLint: React Hook useEffect has a missing dependency: 'currentUser'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid, subscription?.status]);

  const showShareButton = !isInstantListening && !replaceEndShareVariantLoading && (replaceEndShareVariant === 'control' || speechify.isPremium(currentUser));

  // ESLint: 'location' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleItemAction = async (item: IRecord, actionType: ItemActionType, location: string = 'library') => {
    switch (actionType) {
      case ItemActionType.Delete:
        dispatch(itemActions.setConfirmItem({ item, action: actionType }));
        break;

      case ItemActionType.Rename:
        dispatch(itemActions.setItemToRename({ item, titleOverride: title }));
        break;

      case ItemActionType.Move:
        dispatch(itemActions.setItemToMove(item));
        break;

      case ItemActionType.OpenOriginal:
        if (item.recordType === RecordType.PDF) {
          window.open(item.sourceURL, '_blank');
        } else if (item.originalSourceURL) {
          window.open(item.originalSourceURL, '_blank');
        }

        break;

      case ItemActionType.Archive:
        dispatch(itemActions.setConfirmItem({ item, action: actionType }));
        break;

      case ItemActionType.Share:
        handleShareClick();
        break;

      case ItemActionType.DownloadMp3: {
        handleDownloadMenuClick();
        break;
      }
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries && entries.length > 0) {
        setTitleRectWidth(entries[0].contentRect.width);
      }
    });

    if (titleContainerDiv) {
      resizeObserver.observe(titleContainerDiv);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [titleContainerDiv]);

  const handleShareClick = async () => {
    if (item) {
      setShareProps({ loading: true });

      const link = await speechify.shareItem(item.id);

      setShareProps({ loading: false, url: link });
      logSegmentEvent('web_app_library_share_document', { link });
    }
  };

  const handleDownloadMenuClick = async () => {
    logSegmentEvent('web_app_item_page_download_menu_clicked');
    dispatch(itemActions.setItemToDownload(item));
  };

  const handleShareModalOk = () => {
    setShareProps({});
  };

  const handleResumeClick = async () => {
    logSegmentEvent('web_app_resume_pressed', { source: getSource(router.pathname) });

    try {
      await speechify.renewCancelledSubscription();

      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await speechify.auth.currentUser.reload();
      // @ts-expect-error TS(2345): Argument of type 'User | null' is not assignable t... Remove this comment to see the full error message
      dispatch(setUser(speechify.auth.currentUser));

      dispatch(
        toastActions.add({
          description: t('Your subscription has been renewed.'),
          type: 'success'
        })
      );

      setCanResumeCancelledSubscription(false);
      navigate('/settings?tab=Subscription');
    } catch (ex) {
      logError(ex as Error, ErrorSource.SUBSCRIPTION);
      logSegmentEvent('web_app_resume_pressed_error', { source: getSource(router.pathname) });
      window.open('https://checkout.speechify.com/oneclick', 'blank');
    }
  };

  const handleUpgradeClick = () => {
    showUpsellModal(UpsellModalType.Global);
  };

  const handleSupportToggle = useCallback(() => setIsSupportMenuOpen(!isSupportMenuOpen), [isSupportMenuOpen]);
  const handleSupportClose = useCallback(() => setIsSupportMenuOpen(false), []);
  const handleTabToggle = useCallback((tab: SidebarTabs) => dispatch(sidebarActions.toggleTab(tab)), [dispatch]);
  const handleTabClose = useCallback(() => dispatch(sidebarActions.closeTab()), [dispatch]);

  const upgradeText = speechify.isExpired(currentUser) ? 'Renew' : 'Upgrade';

  const maxLength = useMemo(() => {
    return isTablet ? 60 : 18;
  }, [isTablet]);

  return (
    <>
      {!(isInstantListening || isItemOrSharePage()) && showContactSupportBanner && <ContactSupportAlertBanner />}
      <ConfirmItemActionModal />
      <ConfirmPersonalVoiceActionModal />
      <MoveToModal folders={folders} />
      <RenameModal />
      <ItemDownload />

      <ShareModal loading={shareProps.loading} onOk={handleShareModalOk} url={shareProps.url} />

      <Popover
        as="header"
        // @ts-expect-error TS(2322): Type '({ open }: PopoverRenderPropArg) => string |... Remove this comment to see the full error message
        className={({ open }) => {
          if (isInstantListening || isItemOrSharePage()) {
            return;
          }
          return twMerge(
            open ? 'inset-0 overflow-y-auto' : '',
            isItemPage() && hideControls ? 'opacity-0 hover:opacity-100' : 'opacity-100',
            'z-[49]',
            'w-full',
            'bg-glass-0',
            'sticky',
            'top-0',
            'lg:overflow-y-visible'
          );
        }}
      >
        {() => {
          return (
            <Fragment>
              {isInstantListening || isItemOrSharePage() ? (
                <>
                  {!isSidebarLoading && (
                    <div
                      className={twMerge(
                        'bg-glass-200',
                        'dark:bg-glass-700',
                        'fixed',
                        'flex',
                        'items-center',
                        'justify-between',
                        'left-0',

                        'md:bg-gradient-to-b',
                        'md:bg-transparent',
                        'dark:md:bg-transparent',
                        'md:from-white',
                        'dark:md:from-glass-800',
                        'md:via-white/70',
                        'dark:md:via-inherit/70',

                        'pointer-events-none',

                        'md:justify-start',
                        'md:left-16',
                        'md:w-[calc(100%-0px)]',
                        'px-4',
                        'py-[0.875rem]',
                        'top-0',
                        'w-full',
                        'z-50'
                      )}
                    >
                      <div ref={ref => setTitleContainerDiv(ref)} className={twMerge('flex', 'items-center', 'pointer-events-auto')}>
                        {!isTablet && back && <Back />}
                        <h3 className="mr-[0.375rem] text-sm font-bold text-glass-700 dark:text-glass-0">
                          &nbsp;{title && title.length > maxLength ? title.substring(0, maxLength) + '...' : title}&nbsp;
                        </h3>

                        {showShareButton && isTablet && (
                          <button className="mr-[0.375rem] h-4 w-4" onClick={handleShareClick}>
                            <ShareIcon className="dark:[&>path]:fill-glass-0" width={16} height={16} />
                          </button>
                        )}

                        {!isInstantListening && (
                          <ItemActionMenu
                            isInstantListening={isInstantListening}
                            arrowIcons
                            hasOriginal={
                              !!(
                                (item?.originalSourceURL && item.originalSourceURL.indexOf(process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET ?? '') === -1) ||
                                (item?.sourceURL && item?.recordType === RecordType.PDF)
                              )
                            }
                            titleWidthOverride={titleRectWidth}
                            downloadMp3={downloadMp3}
                            isPremium={isPremium}
                            onAction={actionType => handleItemAction(item, actionType)}
                          />
                        )}
                      </div>

                      {avatar && !isTablet && (
                        <div className="relative flex items-center justify-center font-ABCDiatype">
                          {speechify.canUpgrade(currentUser) ? (
                            <button
                              type="button"
                              className="mr-3 rounded-[0.25rem] bg-yellow-400 px-2 py-1 text-xs text-glass-700"
                              onClick={handleUpgradeClick}
                            >
                              Upgrade
                            </button>
                          ) : (
                            <>
                              <button ref={supportButtonRef} className="mr-3 flex h-6 w-6 flex-col items-center justify-center" onClick={handleSupportToggle}>
                                <SupportIcon />
                              </button>
                              <SupportMenu
                                item={item}
                                isOpen={isSupportMenuOpen}
                                handleClose={handleSupportClose}
                                handleTabClose={handleTabClose}
                                handleReportToggle={() => handleTabToggle('report')}
                                // @ts-expect-error TS(2322): Type 'RefObject<HTMLButtonElement>' is not assigna... Remove this comment to see the full error message
                                buttonRef={supportButtonRef}
                              />
                            </>
                          )}
                          <HeaderAvatar isInstantListening={isInstantListening} size={24} />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="mx-auto px-5">
                    <div className="relative flex h-16 items-center justify-between">
                      {back && (
                        <div className="flex items-center px-2 lg:px-0 xl:w-64">
                          <Back />
                        </div>
                      )}
                      {search && <SearchBar />}
                      {!search && (
                        <TitleBar
                          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                          title={title}
                        />
                      )}

                      {avatar && (
                        <div className="relative ml-0 flex-shrink-0 lg:ml-6">
                          <div className="flex items-center">
                            {speechify.canUpgrade(currentUser) && (
                              <button
                                className="mr-4 flex h-9 w-24 items-center justify-center rounded-lg bg-yellow-400 text-sm font-medium text-glass-700"
                                onClick={handleUpgradeClick}
                              >
                                <span>{t(upgradeText)}</span>
                              </button>
                            )}

                            {canResumeCancelledSubscription && (
                              <button
                                className="mr-4 hidden h-9 w-44 items-center justify-center rounded-lg bg-yellow-400 text-sm font-medium text-glass-700 md:flex"
                                onClick={handleResumeClick}
                              >
                                <span>{t('Resume Subscription')}</span>
                              </button>
                            )}

                            {item?.id && showShareButton && (
                              <button className="mr-4 hidden h-9 w-8 items-center justify-center md:flex" onClick={handleShareClick}>
                                <ShareIcon />
                              </button>
                            )}

                            <StreakCounter />

                            <HeaderAvatar isInstantListening={isInstantListening} size={32} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Fragment>
          );
        }}
      </Popover>
    </>
  );
};

export default Header;
