import React from 'react';

import Button from 'components/elements/Button';
import { useTranslation } from 'hooks/useTypedTranslation';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage } from 'utils';

import { ExclamationIcon } from '@heroicons/react/outline';

interface ConfirmModalProps {
  description?: string;
  title?: string;
  id?: string;
  // ESLint: Don't use `Function` as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
  // ESLint: Don't use `Function` as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  onConfirm: Function;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ description, id, onCancel, onConfirm, title }) => {
  const { t } = useTranslation('common');

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof Element && e.target.classList.contains('fixed')) {
      e.preventDefault();
      e.stopPropagation();

      handleCancel();
    }
  };

  if (id) {
    return (
      <div className="fixed inset-0 z-2000 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleOutsideClick}>
        <div
          className={twMerge(
            isItemOrSharePage()
              ? 'min-h-screen px-4 pb-20 pt-4 text-center sm:p-0'
              : 'flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'
          )}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <span
            className={twMerge(isItemOrSharePage() ? 'inline-block h-screen align-middle' : 'hidden sm:inline-block sm:h-screen sm:align-middle')}
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            onMouseDown={event => {
              event.stopPropagation();
            }}
            className={twMerge(
              isItemOrSharePage()
                ? 'my-8 inline-block w-full transform overflow-hidden rounded-lg bg-glass-0 text-left align-middle shadow-xl transition-all dark:bg-glass-700 sm:max-w-lg'
                : 'inline-block transform overflow-hidden rounded-lg bg-glass-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle'
            )}
          >
            <div className={twMerge('px-4', 'pt-5', 'pb-4', isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0', 'sm:p-6', 'sm:pb-4')}>
              <div className={twMerge(isItemOrSharePage() ? 'flex items-start' : 'sm:flex sm:items-start')}>
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full text-red-400 hover:text-red-300 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon />
                </div>
                <div className={twMerge(isItemOrSharePage() ? 'ml-4 mt-0 text-left' : 'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left')}>
                  <h3 className={twMerge('text-lg', 'font-medium', 'leading-6', isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700')}>
                    {title}
                  </h3>
                  <div>
                    <p className={twMerge('text-sm', isItemOrSharePage() ? 'text-glass-500 dark:text-glass-350' : 'text-glass-500')}>{description}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={twMerge(
                isItemOrSharePage()
                  ? 'mt-3 flex flex-row-reverse justify-end bg-gray-50 px-6 py-3 dark:bg-glass-800 sm:justify-start'
                  : 'mt-3 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
              )}
            >
              <Button color="red" onClick={handleConfirm} title={t('Confirm')} className={twMerge(isItemOrSharePage() && 'dark:border-transparent')} />
              <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ConfirmModal;
