import React, { Fragment, useMemo } from 'react';

import FilterIcon from 'assets/icons/filter';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useRouter } from 'next/router';
import { useDispatch } from 'store';
import { actions as libraryActions } from 'store/library';
import { twMerge } from 'tailwind-merge';
import { carryParams } from 'utils';
import { logSegmentEvent } from 'utils/analytics';

import { Menu, Transition } from '@headlessui/react';
import { CameraIcon, DocumentIcon, DocumentTextIcon, GlobeAltIcon } from '@heroicons/react/outline';

type fileFilter = 'all' | 'web' | 'pdf' | 'file' | 'scan' | 'txt' | 'epub';
type itemType = 'All' | 'Web' | 'PDF' | 'File' | 'Scan' | 'Text' | 'EPUB';

export const FilterMenu = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const currentType = useMemo<fileFilter>(() => (router.query.type as fileFilter) || 'all', [router.query.type]);
  const itemTypes = useMemo<itemType[]>(() => ['All', 'Web', 'PDF', 'File', 'Scan', 'Text', 'EPUB'], []);

  const handleTypeSelect = (itemType: string) => {
    const type = itemType === 'Text' ? 'txt' : itemType.toLowerCase();

    logSegmentEvent('web_app_document_filter_used', { type });

    navigate(carryParams('/', { type }));
    dispatch(libraryActions.setCurrentPage(0));
    dispatch(libraryActions.setItemsFilterType(type));
  };

  return (
    <Menu as="div" className="relative inline-block h-5 text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className={twMerge(currentType !== 'all' && 'bg-glass-300', 'cursor-pointer rounded hover:bg-glass-300')}>
              <FilterIcon />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-40 mt-0 w-44 origin-top-right overflow-hidden rounded-md border border-glass-300 bg-white py-1 shadow-lg focus:outline-none"
            >
              {itemTypes.map(itemType => (
                <Menu.Item key={itemType}>
                  <button
                    className={twMerge(
                      currentType === itemType.toLocaleLowerCase() ? 'bg-glass-300' : 'hover:bg-glass-200',
                      'group flex w-full items-center px-4 py-2 text-sm text-glass-700 hover:text-glass-800'
                    )}
                    onClick={handleTypeSelect.bind(null, itemType)}
                  >
                    {(itemType === 'File' || itemType === 'PDF' || itemType === 'EPUB') && (
                      <DocumentIcon className="mr-3 h-5 w-5 text-glass-500 group-hover:text-glass-700" aria-hidden="true" />
                    )}
                    {itemType === 'Scan' && <CameraIcon className="mr-3 h-5 w-5 text-glass-500 group-hover:text-glass-700" aria-hidden="true" />}
                    {(itemType === 'Text' || itemType === 'All') && (
                      <DocumentTextIcon className="mr-3 h-5 w-5 text-glass-500 group-hover:text-glass-700" aria-hidden="true" />
                    )}
                    {itemType === 'Web' && <GlobeAltIcon className="mr-3 h-5 w-5 text-glass-500 group-hover:text-glass-700" aria-hidden="true" />}
                    {t(itemType === 'All' ? 'filter_all' : itemType)}
                  </button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default FilterMenu;
