import React from 'react';

import FolderIcon from 'assets/icons/folder';
import SharedFolderIcon from 'assets/icons/folder-shared';
import { IIntegrationFolder } from 'interfaces/integrations';
import { twMerge } from 'tailwind-merge';

interface FolderCardProps {
  folder: IIntegrationFolder;
  onClick: (folderId: string) => void;
}

export function FolderCard({ folder, onClick }: FolderCardProps) {
  return (
    <div
      className={twMerge(
        'p-3 h-[156px] box-border flex flex-col gap-2 rounded-lg cursor-pointer',
        'transition-colors duration-75 bg-glass-200 hover:bg-glass-hovered-200 active:bg-glass-pressed-200'
      )}
      onClick={() => onClick(folder.id)}
    >
      <div className="text-glass-700 font-medium text-sm whitespace-nowrap overflow-hidden text-ellipsis shrink-0">{folder.name}</div>
      <div className="bg-glass-0 rounded-sm h-full flex items-center justify-center">
        {folder.isShared ? <SharedFolderIcon className="h-12 w-14" /> : <FolderIcon className="h-12 w-14" />}
      </div>
    </div>
  );
}
