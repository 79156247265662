import React from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import { IItemState, IRecord, ItemType } from 'interfaces';
import { isDescendant } from 'utils';

import { selectedItemsStoreActions } from 'modules/library/store/selectedItemsStore';
import { RootState, useDispatch, useSelector } from 'store';
import { actions as itemActions } from 'store/item';
import { actions as libraryActions } from '../../../store/library';
import MoveToModalView from './MoveToModalView';

interface MoveToModalProps {
  folders: IRecord[];
}

export const MoveToModal: React.FC<MoveToModalProps> = ({ folders = [] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { itemToMove: item } = useSelector<IItemState>((state: RootState) => state.item);

  const options = folders
    .filter(folder => {
      if (folder.id === item?.id) {
        return false;
      }

      if (item?.parentFolderId === folder.id) {
        return false;
      }

      if (isDescendant(folders, item, folder)) {
        return false;
      }

      return true;
    })
    .map(folder => ({
      id: folder.id,
      title: folder.title
    }));

  if (item?.parentFolderId !== null) {
    options.unshift({
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      id: null,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      title: t('My Library')
    });
  }

  const handleCancel = () => {
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToMove(undefined));
  };

  const handleSubmit = (parentFolderId: string) => {
    const folderName = options.find(({ id }) => id === parentFolderId)?.title || '';

    dispatch(
      libraryActions.setParentFolderId({
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        itemId: item.id,
        parentFolderId,
        folderName,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        isFolder: item.type === ItemType.Folder
      })
    );

    if (item) {
      if (item.type === ItemType.Folder) {
        selectedItemsStoreActions.removeSelectedFolder(item.id);
      } else {
        selectedItemsStoreActions.removeSelectedItem(item.id);
      }
    }
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    dispatch(itemActions.setItemToMove(undefined));
  };

  return item ? <MoveToModalView options={options} onCancel={handleCancel} onSubmit={handleSubmit} /> : null;
};

export default MoveToModal;
