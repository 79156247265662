import React, { Fragment, MouseEvent, useEffect, useState } from 'react';

import ArrowDown from 'assets/icons/arrow-down';
import ArrowRight from 'assets/icons/arrow-right';
import Library from 'assets/icons/library';
import { FolderModal, TreeNode } from 'components/library';
import { useNavigate } from 'hooks/useNavigate';
import { IRecord, ItemActionType } from 'interfaces';
import * as speechify from 'lib/speechify';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useDispatch } from 'store';
import { actions as libraryActions } from 'store/library';
import { twMerge } from 'tailwind-merge';
import { carryParams } from 'utils';

import { useDroppable } from '@dnd-kit/core';
import { PlusIcon } from '@heroicons/react/outline';

import { TreeNodes } from './TreeNodes';

interface FolderTreeProps {
  activeDropTargetId?: string;
  folders: IRecord[];
  isDragging?: boolean;
  onFolderAction: (folder: IRecord, actionType: ItemActionType) => void;
}

export const FolderTree: React.FC<FolderTreeProps> = ({ activeDropTargetId, folders, isDragging, onFolderAction }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  // state
  const [currentFolderId, setCurrentFolderId] = useState<string | undefined>();

  // This is a duplicated state for new folder behavior that also exists on the DashboardPage component.
  // Technically, we can reuse the isFolderModalOpen state on the DashboardPage component and passing the onNewFolder handler as a prop to this component
  //   and call that function when the plus icon on the "My Library" tab is clicked.
  // But doing that and removing the state declared here will change the behavior a little bit:
  // - Current behavior (by duplicating the state): clicking the Plus icon will not change the navigation of the current folder and only open the new folder modal.
  // - Behavior if we reuse the onNewFolder handler: clicking the Plus icon will open the new folder modal, but also change the current folder navigation back to the "My Library" level.
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  // dnd
  const { setNodeRef: setDropRef } = useDroppable({ id: 'library-item-root-drop' });
  const userUid = speechify.auth.currentUser?.uid;

  useEffect(() => {
    setCurrentFolderId(router.query.folder?.toString() || undefined);

    if (router.query.folder) {
      setIsOpen(true);
    }

    if (userUid) {
      dispatch(libraryActions.setCurrentFolderId(router.query.folder || null));
    }
  }, [userUid, router.query.folder, dispatch]);

  useEffect(() => {
    if (isDragging && !isOpen) {
      setIsOpen(true);
    }
    // ESLint: React Hook useEffect has a missing dependency: 'isOpen'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const handleFolderCancel = () => {
    setIsFolderModalOpen(false);
  };

  const handleFolderClick = (folder?: string) => {
    if (!folder) {
      navigate(carryParams('/'), undefined, { shallow: true });
    }
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    navigate(carryParams('/', { folder }), undefined, { shallow: true });
  };

  const handleFolderAction = (folder: IRecord, actionType: ItemActionType) => {
    switch (actionType) {
      case ItemActionType.Open:
        navigate(`/?folder=${folder.id}`);
        break;
      default:
        onFolderAction(folder, actionType);
    }
  };

  const handleFolderSubmit = (title: string) => {
    // the handleFolderSubmit here is only triggered through the Plus icon from the My Library tab, which should always create a new folder on the root level.
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    dispatch(libraryActions.addFolder({ title, parentFolderId: null }));
    setIsFolderModalOpen(false);
  };

  const handleNewFolderClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFolderModalOpen(true);
  };

  const handleTreeToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <div
        className={twMerge(
          'group flex items-center rounded-[10px]',
          activeDropTargetId === 'root'
            ? 'bg-highlight1 bg-opacity-50'
            : currentFolderId === 'root' || (currentFolderId == undefined && isOpen)
              ? 'bg-glass-300'
              : 'hover:bg-glass-200'
        )}
        ref={setDropRef}
      >
        {isOpen && <ArrowDown className="ml-1 h-5 w-5 cursor-pointer text-gray-400" aria-hidden="true" onClick={handleTreeToggle} />}
        {!isOpen && <ArrowRight className="ml-1 h-5 w-5 cursor-pointer text-gray-400" aria-hidden="true" onClick={handleTreeToggle} />}
        <button className={twMerge('mt-0 flex w-full items-center px-3 py-2 pl-0 text-sm font-medium text-glass-700')} onClick={() => handleFolderClick()}>
          <Library />
          <span className="ml-2 truncate">{t('My Library')}</span>
          <PlusIcon className="invisible ml-auto inline-block h-5 w-5 text-gray-400 group-hover:visible" aria-hidden="true" onClick={handleNewFolderClick} />
        </button>
      </div>
      {isOpen && (
        <div>
          <TreeNodes
            items={folders}
            parentId={null}
            activeDropTargetId={activeDropTargetId}
            currentFolderId={currentFolderId}
            onAction={handleFolderAction}
            onClick={handleFolderClick}
            maxDepth={8}
          />
        </div>
      )}
      <TreeNode
        id="trash"
        isMain={true}
        isActive={currentFolderId === 'trash'}
        isActiveDropTarget={activeDropTargetId === 'trash'}
        key="trash"
        onClick={handleFolderClick}
        title={t('Trash')}
      />
      <FolderModal onCancel={handleFolderCancel} open={isFolderModalOpen} onSubmit={handleFolderSubmit} />
    </Fragment>
  );
};

export default FolderTree;
