import React, { useEffect } from 'react';

import { useNavigate } from 'hooks/useNavigate';
import { IUser } from 'interfaces';
import { auth, isAnonymous } from 'lib/speechify';
import { useRouter } from 'next/router';
import { CgSpinner } from 'react-icons/cg';
import { useDispatch, useSelector } from 'store';
import { actions as authActions } from 'store/auth';
import { getIdToken, getUser, handleAuth } from 'utils/extension';

const USER_CREATION_REDIRECT_DATE_THRESHOLD = new Date('01-11-2023');
const isBetaApp = () => new URL(window.location.href).hostname === 'beta-app.speechify.com';

// ESLint: 'shouldPerformRedirect' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shouldPerformRedirect = (user: IUser) => {
  // if no creation time we can default to app.speechify.com
  // but its not really clear why creationTime will be nullish
  return (
    // if user created after threshold date and they are not on beta app, redirect them
    (new Date(user?.metadata?.creationTime ?? 0) >= USER_CREATION_REDIRECT_DATE_THRESHOLD && !isBetaApp()) ||
    // if user created before threshold date and they are on beta app, redirect them
    (new Date(user?.metadata?.creationTime ?? 0) < USER_CREATION_REDIRECT_DATE_THRESHOLD && isBetaApp())
  );
};

// ESLint: 'redirectToListeningExperience' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const redirectToListeningExperience = () => {
  const url = new URL(window.location.href);
  url.hostname = isBetaApp() ? 'app.speechify.com' : 'beta-app.speechify.com';
  window.location.href = url.href;
};

const withAuth = (Page: $TSFixMe) => {
  const Auth = (props: $TSFixMe) => {
    const router = useRouter();
    // ESLint: 'navigate' is assigned a value but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();
    // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
    const user = useSelector<IUser>(state => state.auth.user);

    const onFailure = () => {
      window.location.href = `/login?returnTo=${encodeURIComponent(window.location.href)}`;
    };

    // ESLint: 'user' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSuccess = (user: IUser) => {};

    if (router.query.ctna) {
      // ESLint: React Hook "useCustomTokenAuth" is called conditionally
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCustomTokenAuth(router.query.ctna as string, onSuccess, onFailure);
    } else {
      // ESLint: React Hook "useSessionAuth" is called conditionally
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSessionAuth(onSuccess, onFailure);
    }

    return user ? (
      <Page {...props} />
    ) : (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <CgSpinner size={30} className="animate-spin" />
      </div>
    );
  };

  return Auth;
};

export default withAuth;

// ESLint: Don't use `Function` as a type & Don't use `Function` as a type
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/ban-types
export const useSessionAuth = (onSuccess: Function, onFailure: Function) => {
  const dispatch = useDispatch();

  useEffect(() => {
    authWithIdToken()
      .then(() => {
        return getCustomToken()
          .then(token => auth?.signInWithCustomToken(token))
          .then(result => {
            const user = result.user;

            if (user) {
              dispatch(authActions.setUser(user));

              // update extension
              user.getIdToken().then(token => handleAuth('idtoken', token));

              onSuccess(user, isAnonymous(user));
            }
          })
          .catch(e => onFailure(e));
      })
      .catch(e => onFailure(e));
    // ESLint: React Hook useEffect has missing dependencies: 'dispatch', 'onFailure', and 'onSuccess'. Either include them or remove the dependency array. If 'onSuccess' changes too often, find the parent component that defines it and wrap that definition in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// ESLint: Don't use `Function` as a type & Don't use `Function` as a type
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/ban-types
export const useCustomTokenAuth = (customToken: string, onSuccess: Function, onFailure: Function) => {
  const dispatch = useDispatch();

  useEffect(() => {
    auth
      .signInWithCustomToken(customToken)
      .then(async result => {
        const user = result.user;

        if (user) {
          dispatch(authActions.setUser(user));

          const userIdToken = await user.getIdToken();
          // update extension.
          await handleAuth('idtoken', userIdToken);

          // set the sign in cookie.
          // ESLint: 'getSignedInCookie' is assigned a value but never used
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const getSignedInCookie = await fetch('/api/auth/sign-in', {
            method: 'GET',
            cache: 'no-store',
            headers: { Authorization: `Bearer ${userIdToken}` }
          });

          onSuccess(user);
        }
      })
      .catch(e => onFailure(e));
    // ESLint: React Hook useEffect has missing dependencies: 'customToken', 'dispatch', 'onFailure', and 'onSuccess'. Either include them or remove the dependency array. If 'onSuccess' changes too often, find the parent component that defines it and wrap that definition in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const getCustomToken = async () => (await (await fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache' })).json())?.token;

const authWithIdToken = async () => {
  const verifiedCookie = await fetch('/api/auth/check-status', { method: 'GET' })
    .then(response => response.json())
    .catch(e => console.info(e));

  // fallback to extension to acquire token
  if (!verifiedCookie) {
    const user = await getUser();

    if (user && !isAnonymous(user)) {
      const token = await getIdToken();

      // set the sign in cookie
      // ESLint: 'getSignedInCookie' is assigned a value but never used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const getSignedInCookie = await fetch('/api/auth/sign-in', {
        method: 'GET',
        cache: 'no-store',
        headers: { Authorization: `Bearer ${token}` }
      });
      const result = await (await fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache' })).json();

      if (result?.token) {
        const user = await auth
          .signOut()
          .then(() => auth.signInWithCustomToken(result.token))
          .catch(e => console.info(e));

        return user;
      }
    } else {
      throw new Error('No user authenticated');
    }
  } else
    return {
      displayName: verifiedCookie.name,
      uid: verifiedCookie.uid,
      isAnonymous: false,
      email: verifiedCookie.email,
      email_verified: verifiedCookie.email_verified
    };
};
