import React from 'react';
import EpubIcon from 'assets/icons/epub';
import PdfIcon from 'assets/icons/pdf';
import ScanIcon from 'assets/icons/scan';
import TxtIcon from 'assets/icons/txt';
import WebIcon from 'assets/icons/web';
import { RecordType } from 'interfaces';

export type RowIconProps = {
  kind: RecordType;
  className?: string;
};

export default function RowIcon(props: RowIconProps) {
  const { kind, ...rest } = props;
  return (
    <>
      {(kind === RecordType.FILE || kind === RecordType.PDF || kind === RecordType.UNKNOWN) && <PdfIcon {...rest} />}
      {kind === RecordType.EPUB && <EpubIcon {...rest} />}
      {kind === RecordType.SCAN && <ScanIcon {...rest} />}
      {kind === RecordType.TXT && <TxtIcon {...rest} />}
      {kind === RecordType.WEB && <WebIcon {...rest} />}
    </>
  );
}
