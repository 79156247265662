import React, { FocusEvent } from 'react';

import { useOnClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'hooks/useTypedTranslation';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'store';
import { actions as toastActions } from 'store/toast';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage } from 'utils';
import { logSegmentEvent } from 'utils/analytics';

import { LinkIcon, XIcon } from '@heroicons/react/solid';

import Loading from '../../elements/Loading';

interface QRCodeModalProps {
  loading?: boolean;
  // ESLint: Don't use `Function` as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOk: Function;
  url?: string;
}

export const ShareModal: React.FC<QRCodeModalProps> = ({ loading, onOk, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const containerRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => onOk());

  const handleCloseClick = () => {
    onOk();
  };

  const handleCopyToClipboard = async (text: string, result: boolean) => {
    if (result) {
      dispatch(
        toastActions.add({
          description: t('Copied to clipboard'),
          type: 'success'
        })
      );

      setTimeout(onOk, 250);

      logSegmentEvent('web_app_copied_to_clipboard', { link: text });
    }
  };

  const handleUrlFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  if (loading || url) {
    return (
      <div className="fixed inset-0 z-2000 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div
          className={twMerge(
            isItemOrSharePage()
              ? 'min-h-screen px-4 pb-20 pt-4 text-center sm:p-0'
              : 'flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'
          )}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <span
            className={twMerge(isItemOrSharePage() ? 'inline-block h-screen align-middle' : 'hidden sm:inline-block sm:h-screen sm:align-middle')}
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            ref={containerRef}
            className={twMerge(
              isItemOrSharePage()
                ? 'my-8 inline-block w-full transform overflow-hidden rounded-lg bg-glass-0 text-left align-middle shadow-xl transition-all dark:bg-glass-700 sm:max-w-lg'
                : 'inline-block transform overflow-hidden rounded-lg bg-glass-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle'
            )}
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className={twMerge(
                  isItemOrSharePage() ? 'text-glass-500 dark:text-glass-350' : 'text-glass-500',
                  'rounded-md',
                  isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0',
                  'hover:text-glass-500'
                )}
                onClick={handleCloseClick}
              >
                <span className="sr-only">{t('Close')}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className={twMerge(isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0')}>
              {loading && (
                <div className="mb-4 mt-4 flex h-28 items-center justify-center">
                  <Loading />
                </div>
              )}
              {!loading && (
                <div className="mx-8 mb-10 mt-6">
                  <div>
                    <h3 className={twMerge('text-lg', 'font-medium', 'leading-6', isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700')}>
                      {t('Copy Share Link')}
                    </h3>
                  </div>
                  <div className="mt-6 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <LinkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="url"
                        name="url"
                        id="url"
                        className={twMerge(
                          'block',
                          'w-full',
                          'border',
                          isItemOrSharePage() ? 'border-glass-300 dark:border-glass-600' : 'border-glass-300',
                          'rounded-none',
                          'rounded-l-md',
                          'pl-9',
                          'sm:text-sm',
                          isItemOrSharePage() ? 'bg-glass-200 dark:bg-glass-800' : 'bg-glass-200',
                          isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700'
                        )}
                        placeholder=""
                        readOnly
                        value={url}
                        onFocus={handleUrlFocus}
                      />
                    </div>
                    <CopyToClipboard
                      onCopy={handleCopyToClipboard}
                      // @ts-expect-error TS(2769): No overload matches this call.
                      text={url}
                    >
                      <button
                        type="button"
                        className={twMerge(
                          'relative',
                          'inline-flex',
                          'items-center',
                          'px-2',
                          'py-2',
                          '-ml-px',
                          'space-x-2',
                          'text-sm',
                          'font-medium',
                          isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700',
                          'border',
                          isItemOrSharePage() ? 'border-glass-300 dark:border-glass-600' : 'border-glass-300',
                          'rounded-r-md',
                          isItemOrSharePage() ? 'bg-gray-50 hover:bg-gray-100 dark:bg-glass-800 dark:hover:bg-glass-600' : 'bg-gray-50 hover:bg-gray-100'
                        )}
                      >
                        <span>{t('Copy')}</span>
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ShareModal;
