import React from 'react';

import DocsIcon from 'assets/icons/docs';
import EpubIcon from 'assets/icons/epub';
import PdfIcon from 'assets/icons/pdf';
import TxtIcon from 'assets/icons/txt';
import { ALLOWED_MIME_TYPES } from 'interfaces/import';
import { twMerge } from 'tailwind-merge';

import { DocumentTextIcon } from '@heroicons/react/solid';

import { matchMimeType } from '../utils';

interface FileIconProps {
  mimeType: string;
  className?: string;
}

export function FileIcon({ mimeType, className }: FileIconProps) {
  switch (mimeType as ALLOWED_MIME_TYPES) {
    case ALLOWED_MIME_TYPES.DOC:
    case ALLOWED_MIME_TYPES.DOCX:
    case ALLOWED_MIME_TYPES.GDOC:
      return <DocsIcon className={className} />;
    case ALLOWED_MIME_TYPES.EPUB:
      return <EpubIcon className={className} />;
    case ALLOWED_MIME_TYPES.PDF:
      return <PdfIcon className={className} />;
    case ALLOWED_MIME_TYPES.RTF:
    case ALLOWED_MIME_TYPES.TXT:
    case ALLOWED_MIME_TYPES.ALL_TEXT:
      return <TxtIcon className={className} />;
  }

  if (mimeType && matchMimeType(ALLOWED_MIME_TYPES.ALL_TEXT, mimeType)) {
    return <TxtIcon className={className} />;
  }

  return <DocumentTextIcon className={twMerge('text-glass-500', className)} />;
}
