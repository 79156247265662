import { UUID } from 'interfaces';
import { createNonPersistentStore } from 'lib/zustand';

type SelectedItems = {
  selectedItems: UUID[];
  selectedFolders: UUID[];
};

export const useSelectedItemsStore = createNonPersistentStore<SelectedItems>(() => {
  return { selectedItems: [], selectedFolders: [] };
});

const addSelectedItem = (itemId: UUID) => {
  useSelectedItemsStore.setState(({ selectedItems }) => ({ selectedItems: [...selectedItems, itemId] }));
};

const removeSelectedItem = (itemId: UUID) => {
  useSelectedItemsStore.setState(({ selectedItems }) => ({ selectedItems: selectedItems.filter(id => id !== itemId) }));
};

const addSelectedFolder = (itemId: UUID) => {
  useSelectedItemsStore.setState(({ selectedFolders }) => ({ selectedFolders: [...selectedFolders, itemId] }));
};

const removeSelectedFolder = (itemId: UUID) => {
  useSelectedItemsStore.setState(({ selectedFolders }) => ({ selectedFolders: selectedFolders.filter(id => id !== itemId) }));
};

const resetSelectedItemsStore = () => {
  useSelectedItemsStore.setState({ selectedItems: [], selectedFolders: [] });
};

export const selectedItemsStoreActions = {
  addSelectedItem,
  removeSelectedItem,
  addSelectedFolder,
  removeSelectedFolder,
  resetSelectedItemsStore
};
