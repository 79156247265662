import React from 'react';
import { IntegrationFileType, IntegrationItemRecord, IntegrationService } from 'interfaces/integrations';
import { FileRow } from './FileRow';
import { LoadingRowSkeleton } from './LoadingRowSkeleton';
import { range } from 'lodash';
import { FolderRow } from './FolderRow';
import { useTranslation } from 'hooks/useTypedTranslation';

const DEFAULT_ITEM_HEIGHT = 46;
const DEFAULT_LOADING_ITEMS = 10;

interface ItemsListProps {
  service: IntegrationService;
  items: IntegrationItemRecord[];
  onFolderChange: (folderId: string) => void;
  isLoading: boolean;

  viewportWidth?: number;
  viewportHeight?: number;
}

export function ItemsList({ service, items, isLoading, onFolderChange, viewportHeight }: ItemsListProps) {
  const itemCount = items.length;
  const maxItemsInViewport = Math.ceil((viewportHeight ?? 0) / DEFAULT_ITEM_HEIGHT) || DEFAULT_LOADING_ITEMS;

  return (
    <div className="w-full leading-5">
      {items.map(item =>
        item.type === IntegrationFileType.FOLDER ? (
          <FolderRow key={item.id} folder={item} onClick={onFolderChange} className="grid grid-cols-[auto_114px_68px] border-b border-solid" />
        ) : (
          <FileRow key={item.id} service={service} item={item} className="grid grid-cols-[auto_114px_68px] border-b border-solid" />
        )
      )}
      {isLoading &&
        range(0, itemCount >= maxItemsInViewport ? 1 : maxItemsInViewport - itemCount).map(i => (
          <LoadingRowSkeleton className="grid grid-cols-[auto_114px_68px] border-b border-solid" key={`item-row-loading-${i}`} />
        ))}
    </div>
  );
}

export function ItemsListHeader() {
  const { t } = useTranslation('common');

  return (
    <div className="grid grid-cols-[auto_114px_68px] border-b border-solid font-normal text-sm text-glass-700">
      <div className="pr-2 py-2">{t('Name')}</div>
      <div className="p-2">{t('Date Added')}</div>
    </div>
  );
}
