import React, { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as yup from 'yup';
import { ChevronLeftIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { IntegrationService } from 'interfaces/integrations';
import { useDispatch } from 'store';
import { actions as integrationActions } from 'store/integration';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useCanvasData } from './hooks/useCanvasData';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';
import { IntegrationView } from './IntegrationView';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spinner } from 'assets/images';
import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';
import { CanvasIntegrationTestVariant } from 'constants/featureDefinitions';
import { CanvasImport } from './CanvasImport';
import { logError } from 'lib/observability';

enum CanvasAuthSteps {
  BASE_URL = 'base_url',
  TOKEN_INFO = 'token_info',
  TOKEN = 'token'
}

export type CanvasIntegrationFormFields = {
  baseUrl: string;
  accessToken: string;
};

const CDN_URL = process.env.NEXT_PUBLIC_CDN_BASEURL;

const formSchema = yup.object().shape({
  baseUrl: yup.string().required('Base url is required'),
  accessToken: yup.string().required('Access token is required')
});

const withHttps = (url: string) => (!/:\/\//i.test(url) ? `https://${url}` : url);

const WebsiteSectionHighlight = (props: HTMLAttributes<HTMLSpanElement>) => {
  return <span className="rounded-md bg-glass-300 px-2 py-1 text-glass-500" {...props}></span>;
};

export function CanvasView() {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [authorizeData, isAuthorizing, authorize] = useIntegrationAuth(IntegrationService.CANVAS);
  const { canvasData, isLoading } = useCanvasData({ cache: true });
  const [step, setStep] = useState<CanvasAuthSteps>(CanvasAuthSteps.BASE_URL);

  const connectButtonRef = useRef<HTMLButtonElement>(null);

  const canvasIntegrationVariant = useFeatureVariant(FeatureNameEnum.CANVAS_INTEGRATION);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    setError,
    register
  } = useForm<CanvasIntegrationFormFields>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: canvasData ? { accessToken: canvasData.token, baseUrl: canvasData.apiBaseUrl } : undefined
  });

  const baseUrl = watch('baseUrl');
  const accessToken = watch('accessToken');

  const handleConnectClick = useMemo(
    () =>
      handleSubmit(async canvasAuth => {
        let baseUrl = canvasAuth.baseUrl;

        try {
          baseUrl = new URL(withHttps(canvasAuth.baseUrl.toLowerCase())).origin;
        } catch (e) {
          logError(e as Error);
          setError('baseUrl', {
            message: 'Provided base url is not a valid URL. Please check whether you have specified correct URL for your university.'
          });
          setStep(CanvasAuthSteps.BASE_URL);
          return;
        }

        const testCall = await fetch(`/api/checkCanvas`, {
          method: 'POST',
          body: JSON.stringify({ ...canvasAuth, baseUrl }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const testCallData = await testCall.json();

        if (!testCallData.ok) {
          setError('accessToken', {
            message: testCallData.message ?? t('Something went wrong. Please check data and try again')
          });
          return;
        }

        const authorized = await authorize({ apiBaseUrl: baseUrl, accessToken: canvasAuth.accessToken });

        if (!authorized) {
          setError('accessToken', {
            message: t('Something went wrong. Please check data and try again')
          });
        }

        dispatch(integrationActions.fetchAuths());
      }),
    [t, dispatch, setError, authorize, handleSubmit]
  );

  useEffect(() => {
    if (
      canvasData &&
      !authorizeData.authorized &&
      !canvasIntegrationVariant.isLoading &&
      canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.ENABLED
    ) {
      setStep(CanvasAuthSteps.TOKEN);
      const handle = requestAnimationFrame(() => {
        connectButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
        handleConnectClick();
      });
      return () => cancelAnimationFrame(handle);
    }
  }, [canvasIntegrationVariant, authorizeData.authorized, canvasData, handleConnectClick]);

  if (canvasIntegrationVariant.isLoading || (!authorizeData.authorized && isLoading)) {
    return <div className="flex flex-col min-h-0 flex-1 overflow-y-auto" />;
  }

  if (canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.CONTROL) {
    return <CanvasImport />;
  }

  if (authorizeData.authorized && !isSubmitting) {
    return <IntegrationView service={IntegrationService.CANVAS} />;
  }

  return (
    <div className="flex flex-col min-h-0 flex-1 overflow-y-auto items-center">
      <div className="flex items-center w-[430px] mt-8 pb-7 mb-5">
        <div className="relative">
          <span className="block h-6 w-6 font-bold text-glass-0 rounded-full bg-bg-secondary-cta text-center">1</span>
          <span className="font-bold glass-700 whitespace-nowrap absolute top-full left-1/2 -translate-x-1/2 mt-1">{t('Log in')}</span>
        </div>
        <div className="w-full h-[2px] bg-bg-secondary-cta" />
        <div className="relative">
          <span
            className={twMerge(
              'block h-6 w-6 font-bold rounded-full text-center',
              step === CanvasAuthSteps.BASE_URL ? 'text-glass-400 bg-glass-200' : 'text-glass-0 bg-bg-secondary-cta'
            )}
          >
            2
          </span>
          <span
            className={twMerge(
              'font-bold whitespace-nowrap absolute top-full left-1/2 -translate-x-1/2 mt-1',
              step === CanvasAuthSteps.BASE_URL ? 'text-glass-500' : 'text-glass-700'
            )}
          >
            {t('Integrations')}
          </span>
        </div>
        <div className={twMerge('w-full h-[2px] bg-bg-secondary-cta', step === CanvasAuthSteps.BASE_URL && 'bg-glass-300')} />
        <div className="relative">
          <span
            className={twMerge(
              'block h-6 w-6 font-bold rounded-full text-center',
              step === CanvasAuthSteps.TOKEN ? 'text-glass-0 bg-bg-secondary-cta' : 'text-glass-400 bg-glass-200'
            )}
          >
            3
          </span>
          <span
            className={twMerge(
              'font-bold whitespace-nowrap absolute top-full left-1/2 -translate-x-1/2 mt-1',
              step === CanvasAuthSteps.TOKEN ? 'text-glass-700' : 'text-glass-500'
            )}
          >
            {t('Token')}
          </span>
        </div>
      </div>
      {step === CanvasAuthSteps.BASE_URL && (
        <div className="w-[410px] h-full flex flex-col min-h-0 flex-1">
          <div className="py-3 px-2">
            <img src={`${CDN_URL}/web/integrations/canvas_1.png`} />
          </div>
          <div className="mt-2.5 -mb-1.5 leading-9">
            {t('Log into your Canvas account, on the left, click')} <WebsiteSectionHighlight>Account</WebsiteSectionHighlight> {t('then open')}{' '}
            <WebsiteSectionHighlight>Settings</WebsiteSectionHighlight>. {t('Paste your school’s link below:')}
          </div>
          <div className="mt-5 text-sm">
            <ReactTooltip className="canvas-tooltip bg-[#14202E] text-[#D9D9D9]" id="base-url-tooltip" variant="dark">
              <div className="w-auto  p-1" style={{ maxWidth: '280px' }}>
                <p className="text-base">{t('Located in your browser’s address bar when you login to Canvas:')}</p>
                <img src="/images/dashboard/canvas_lms_3.png" className="h-auto w-full" />
              </div>
            </ReactTooltip>
            <label htmlFor="baseUrl" className="inline-flex items-center gap-1 font-bold mb-1">
              {t('Institution Base Url')}
              <QuestionMarkCircleIcon data-tip data-tooltip-id="base-url-tooltip" className="h-5 w-5 -mt-px cursor-pointer text-glass-500" />
            </label>
            <input
              id="baseUrl"
              type="text"
              placeholder="https://canvas.vt.edu"
              {...register('baseUrl')}
              disabled={isSubmitting || isAuthorizing}
              className={twMerge(
                'block w-full h-10 transition-colors rounded-md text-sm',
                'border border-border-primary hover:border-border-secondary-cta-hovered focus:border-border-secondary-cta-pressed',
                'placeholder:text-text-placeholder text-text-primary'
              )}
            />
            {errors.baseUrl?.message && <div className="flex-none py-2 text-red-400 first-letter:uppercase">{errors.baseUrl.message}</div>}
          </div>
          <div className="py-8 mt-auto">
            <button
              disabled={!!errors.baseUrl || !baseUrl}
              className={twMerge(
                'w-full transition-colors font-bold rounded-lg py-2.5',
                'bg-spl-electric-400 hover:bg-spl-electric-400h active:bg-spl-electric-400p text-glass-0',
                'disabled:bg-spl-dsbl-200 disabled:text-spl-dsbl-300'
              )}
              onClick={() => setStep(CanvasAuthSteps.TOKEN_INFO)}
            >
              {t('Next')}
            </button>
          </div>
        </div>
      )}
      {step === CanvasAuthSteps.TOKEN_INFO && (
        <div className="w-[410px] h-full flex flex-col min-h-0 flex-1">
          <div className="py-3 px-2">
            <img src={`${CDN_URL}/web/integrations/canvas_2.png`} />
          </div>
          <div className="mt-2.5 -mb-1.5 -mx-12 leading-9">
            {t('Scroll down the settings page until you find the "Approved Integrations" section.')} {t('Locate and click')}{' '}
            <WebsiteSectionHighlight>+ New Access Token</WebsiteSectionHighlight>.
          </div>
          <div className="py-8 mt-auto flex gap-6">
            <button
              className="inline-flex gap-1 transition-opacity font-bold rounded-lg py-2.5 px-5 hover:opacity-75 active:opacity-50 text-glass-500"
              onClick={() => setStep(CanvasAuthSteps.BASE_URL)}
            >
              <ChevronLeftIcon className="w-4 h-4 m-1 ml-0" />
              {t('Back')}
            </button>
            <button
              className={twMerge(
                'w-full transition-colors font-bold rounded-lg py-2.5',
                'bg-spl-electric-400 hover:bg-spl-electric-400h active:bg-spl-electric-400p text-glass-0'
              )}
              onClick={() => setStep(CanvasAuthSteps.TOKEN)}
            >
              {t('Next')}
            </button>
          </div>
        </div>
      )}
      {step === CanvasAuthSteps.TOKEN && (
        <div className="w-[410px] h-full flex flex-col min-h-0 flex-1">
          <div className="py-3 px-6">
            <img src={`${CDN_URL}/web/integrations/canvas_3.png`} />
          </div>
          <div className="mt-2.5 -mb-1.5 leading-9">
            {t('Fill in')} <WebsiteSectionHighlight>Purpose</WebsiteSectionHighlight> {t('field of the new Token, e.g. "Speechify".')}
            <br />
            {t('Leave')} <WebsiteSectionHighlight>Expires</WebsiteSectionHighlight> {t('field empty. Fill in generated token below.')}
          </div>
          <div className="mt-5 text-sm">
            <ReactTooltip className="canvas-tooltip bg-[#14202E] text-[#D9D9D9]" id="access-token-tooltip" variant="dark">
              <div className="w-auto  p-1" style={{ maxWidth: '280px' }}>
                <p className="text-base">{t('Located in first row of Access Token Details window:')}</p>
                <img src="/images/dashboard/canvas_lms_4.png" className="h-auto w-full" />
              </div>
            </ReactTooltip>
            <label htmlFor="accessToken" className="inline-flex items-center gap-1 font-bold mb-1">
              {t('Canvas Access Token')}
              <QuestionMarkCircleIcon data-tip data-tooltip-id="access-token-tooltip" className="h-5 w-5 -mt-px cursor-pointer text-gray-500" />
            </label>
            <input
              id="accessToken"
              type="text"
              placeholder="1234~xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              {...register('accessToken')}
              disabled={isSubmitting || isAuthorizing}
              className={twMerge(
                'block w-full h-10 transition-colors rounded-md text-sm',
                'border border-border-primary hover:border-border-secondary-cta-hovered focus:border-border-secondary-cta-pressed',
                'placeholder:text-text-placeholder text-text-primary'
              )}
            />
            {errors.accessToken?.message && <div className="flex-none py-2 text-red-400 first-letter:uppercase">{errors.accessToken.message}</div>}
          </div>
          <div className="py-8 mt-auto flex gap-6">
            <button
              className="inline-flex gap-1 transition-opacity font-bold rounded-lg py-2.5 px-5 hover:opacity-75 active:opacity-50 text-glass-500"
              onClick={() => setStep(CanvasAuthSteps.TOKEN_INFO)}
            >
              <ChevronLeftIcon className="w-4 h-4 m-1 ml-0" />
              {t('Back')}
            </button>
            <button
              disabled={!!errors.accessToken || !accessToken || isSubmitting || isAuthorizing}
              className={twMerge(
                'w-full transition-colors font-bold rounded-lg py-2.5 inline-flex gap-2 justify-center items-center',
                'bg-spl-electric-400 hover:bg-spl-electric-400h active:bg-spl-electric-400p text-glass-0',
                'disabled:bg-spl-dsbl-200 disabled:text-spl-dsbl-300'
              )}
              onClick={handleConnectClick}
              ref={connectButtonRef}
            >
              {(isSubmitting || isAuthorizing) && <Spinner width="20" fill="white" className="animate-spin inline-block" />}
              {t('Connect')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
