import React, { useEffect } from 'react';

import Button from 'components/elements/Button';
import Modal from 'components/elements/Modal';
import TextInput from 'components/elements/TextInput';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { DOCUMENT_TITLE_MAX_LENGTH } from 'utils/constants';

interface FolderModalProps {
  onCancel: () => void;
  onSubmit: (name: string) => void;
  open: boolean;
}

export const FolderModal: React.FC<FolderModalProps> = ({ onCancel, onSubmit, open = false }) => {
  const { t } = useTranslation('common');

  const schema = yup.object().shape({
    name: yup.string().required(t('A valid folder name is required'))
  });
  type FolderFormFields = { name: string };

  const {
    formState: { errors },
    handleSubmit: handleSubmitWrapper,
    register,
    setFocus
  } = useForm<FolderFormFields>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFocus('name');
      }, 250);
    }
  }, [setFocus, open]);

  const handleSubmit = (data: { name: string }) => {
    onSubmit(data.name);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <form onSubmit={handleSubmitWrapper(handleSubmit)}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Add New Folder')}</h3>
            </div>

            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                {t('Folder name')}
              </label>
              <div className="mt-1">
                <TextInput
                  error={errors.name}
                  maxLength={DOCUMENT_TITLE_MAX_LENGTH}
                  // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                  name="name"
                  {...register('name')}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse bg-gray-50 px-6 py-3 text-right">
            <Button title={t('Submit')} type="submit" />
            <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FolderModal;
