import 'react-multi-carousel/lib/styles.css';

import React from 'react';

import { ArrowLeft, ArrowRight } from 'assets/icons/slider';
import Carousel, { ResponsiveType } from 'react-multi-carousel';

const responsive: ResponsiveType = {
  xl: {
    breakpoint: { max: 3000, min: 1280 },
    items: 4
  },
  lg: {
    breakpoint: { max: 1280, min: 1024 },
    items: 3
  },
  sm: {
    breakpoint: { max: 1024, min: 640 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1
  }
};

const CarouselSlider = ({ items }: { items: JSX.Element[] }) => {
  // ESLint: 'goToSlide' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: $TSFixMe) => {
    const {
      carouselState: { currentSlide, slidesToShow, totalItems }
    } = rest;

    return (
      <div className="mb-4 flex justify-between pr-5">
        <div className="flex-none font-ABCDiatype text-lg font-bold text-glass-700">Recently listened</div>
        <div className="flex">
          <button className="" color="default" disabled={currentSlide === 0} onClick={() => previous()}>
            <ArrowLeft disabled={currentSlide === 0} />
          </button>
          <button className="ml-2 border-none bg-transparent" disabled={currentSlide + slidesToShow === totalItems} onClick={() => next()}>
            <ArrowRight disabled={currentSlide + slidesToShow === totalItems} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col-reverse">
      <Carousel
        swipeable={true}
        draggable={false}
        arrows={false}
        containerClass="!overflow-visible !overflow-x-clip"
        itemClass="pr-5"
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={true}
        renderArrowsWhenDisabled={true}
        partialVisbile={false}
        responsive={responsive}
      >
        {items.map(item => item)}
      </Carousel>
    </div>
  );
};

export default CarouselSlider;
