import React from 'react';
import { Fragment } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import { carryParams } from 'utils';
import { logSegmentEvent } from 'utils/analytics';
import { ViewType } from 'interfaces';
import { useNavigate } from 'hooks/useNavigate';

import { Menu, Transition } from '@headlessui/react';
import { CardViewIcon, ListViewIcon } from 'assets/icons/itemView';

export const SortMenu = ({ setViewType, viewType }: $TSFixMe) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleGridViewClick = () => {
    logSegmentEvent('web_app_view_type_changed', { type: 'grid' });

    setViewType(ViewType.GRID);
    navigate(carryParams('/', { view: ViewType.GRID }), undefined, { shallow: true });
  };

  const handleListViewClick = () => {
    logSegmentEvent('web_app_view_type_changed', { type: 'list' });

    setViewType(ViewType.LIST);
    navigate(carryParams('/', { view: ViewType.LIST }), undefined, { shallow: true });
  };

  return (
    <Menu as="div" className="relative inline-block h-5 text-left">
      {({ open }) => (
        <>
          <Menu.Button className="rounded hover:bg-glass-300">
            {viewType === ViewType.GRID ? <CardViewIcon aria-hidden="true" /> : <ListViewIcon aria-hidden="true" />}
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-40 mt-0 w-44 origin-top-right overflow-hidden rounded-md border border-glass-300 bg-white py-1 shadow-lg focus:outline-none"
            >
              <Menu.Item key="card">
                {() => (
                  <button className="group flex w-full items-center gap-2 px-4 py-2 text-sm text-glass-700 hover:bg-glass-200" onClick={handleGridViewClick}>
                    <CardViewIcon aria-hidden="true" />
                    {t('Card view')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item key="list">
                {() => (
                  <button className="group flex w-full items-center gap-2 px-4 py-2 text-sm text-glass-700 hover:bg-glass-200" onClick={handleListViewClick}>
                    <ListViewIcon aria-hidden="true" />
                    {t('List view')}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SortMenu;
