import React, { useMemo } from 'react';
import { IntegrationFileType, IntegrationItemRecord, IntegrationService } from 'interfaces/integrations';
import { range } from 'lodash';
import { FolderCard } from './FolderCard';
import { FileCard } from './FileCard';
import { LoadingCardSkeleton } from './LoadingCardSkeleton';

const GAP_SIZE = 20;
const MAX_ITEM_HEIGHT = 156;
const MAX_ITEM_WIDTH = 235;
const DEFAULT_LOADING_ROWS = 3;
const DEFAULT_GRID_COLS = 4;

interface ItemsGridProps {
  service: IntegrationService;
  items: IntegrationItemRecord[];
  onFolderChange: (folderId: string) => void;
  isLoading: boolean;

  viewportWidth?: number;
  viewportHeight?: number;
}

export function ItemsGrid({ service, items, isLoading, onFolderChange, viewportWidth, viewportHeight }: ItemsGridProps) {
  const gridCols = useMemo(() => Math.max(Math.ceil((viewportWidth ?? 0) / (MAX_ITEM_WIDTH + GAP_SIZE)), DEFAULT_GRID_COLS), [viewportWidth]);
  const minRows = useMemo(() => Math.max(Math.ceil((viewportHeight ?? 0) / (MAX_ITEM_HEIGHT + GAP_SIZE)), DEFAULT_LOADING_ROWS), [viewportHeight]);

  const maxLoadingItems = minRows * gridCols;
  const itemsCount = items.length;

  return (
    <div
      className="grid gap-5 pt-2"
      style={{
        gridTemplateColumns: `repeat(${gridCols}, minmax(0, 1fr))`
      }}
    >
      {items.map(item =>
        item.type === IntegrationFileType.FOLDER ? (
          <FolderCard key={item.id} folder={item} onClick={onFolderChange} />
        ) : (
          <FileCard key={item.id} service={service} item={item} />
        )
      )}

      {isLoading &&
        range(0, (itemsCount >= maxLoadingItems ? gridCols : maxLoadingItems) - (itemsCount % gridCols)).map(i => (
          <LoadingCardSkeleton key={`item-card-loading-${i}`} />
        ))}
    </div>
  );
}
