import React, { ReactNode, useCallback, useEffect } from 'react';
import dynamic from 'next/dynamic';
import ScanSourceIcon from 'assets/icons/scan-source';
import AIStorySourceIcon from 'assets/icons/ai-story';
import TextSourceIcon from 'assets/icons/text-source';
import { VoiceoverIcon } from 'assets/icons/voice-over';
import WebSourceIcon from 'assets/icons/web-source';
import ModalComponent from 'components/elements/Modal';
import { TranslationKeys, useTranslation } from 'hooks/useTypedTranslation';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import { IUser } from 'interfaces';
import { ImportSource } from 'interfaces/import';
import { IntegrationService } from 'interfaces/integrations';
import { isPremium, isStripe } from 'lib/speechify';
import { useDispatch, useSelector } from 'store';
import { actions as importActions, selectors as importSelectors } from 'store/import';
import { actions as integrationActions, selectors as integrationSelectors } from 'store/integration';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';

import { ArrowRightIcon, ChevronLeftIcon, XIcon } from '@heroicons/react/outline';

import { Dropzone } from './Dropzone';
import { ImportSourceCard } from './ImportSourceCard';
import { IntegrationDialogTitle } from './IntegrationDialogTitle';
import { IntegrationImportCard } from './IntegrationImportCard';
import { CanvasIntegrationCard } from './CanvasIntegrationCard';
import { IntegrationView } from './IntegrationView';
import { ScanImport } from './ScanImport';
import { TextImport } from './TextImport';
import { UrlImport } from './UrlImport';
import { CanvasView } from './CanvasView';
import { useAvailableIntegrations } from './hooks/useAvailableIntegrations';

const AIStoryImport = dynamic(() => import('./AIStoryImport').then(mod => mod.AIStoryImport), { ssr: false });

const importSourceConfig: Record<ImportSource, { card: ReactNode; title: TranslationKeys['common'] | JSX.Element; content: ReactNode }> = {
  [IntegrationService.GOOGLE_DRIVE]: {
    content: <IntegrationView service={IntegrationService.GOOGLE_DRIVE} />,
    card: <IntegrationImportCard service={IntegrationService.GOOGLE_DRIVE} />,
    title: <IntegrationDialogTitle service={IntegrationService.GOOGLE_DRIVE} />
  },
  [IntegrationService.DROPBOX]: {
    content: <IntegrationView service={IntegrationService.DROPBOX} />,
    card: <IntegrationImportCard service={IntegrationService.DROPBOX} />,
    title: <IntegrationDialogTitle service={IntegrationService.DROPBOX} />
  },
  [IntegrationService.ONE_DRIVE]: {
    content: <IntegrationView service={IntegrationService.ONE_DRIVE} />,
    card: <IntegrationImportCard service={IntegrationService.ONE_DRIVE} />,
    title: <IntegrationDialogTitle service={IntegrationService.ONE_DRIVE} />
  },
  [IntegrationService.CANVAS]: {
    content: <CanvasView />,
    card: <CanvasIntegrationCard />,
    title: <IntegrationDialogTitle service={IntegrationService.CANVAS} />
  },
  text: {
    content: <TextImport />,
    card: <ImportSourceCard title="Type or Paste Text" icon={TextSourceIcon} buttonType="outline" buttonText="Add Text" />,
    title: 'Add Text'
  },
  web: {
    content: <UrlImport />,
    card: <ImportSourceCard title="Paste Web Link" icon={WebSourceIcon} buttonType="outline" buttonText="Add Link" />,
    title: 'Paste Web Link'
  },
  aiStory: {
    content: <AIStoryImport />,
    card: <ImportSourceCard title="AI Story" icon={AIStorySourceIcon} buttonType="outline" buttonText="Create" />,
    title: 'Create AI Story'
  },
  scan: {
    content: <ScanImport />,
    card: <ImportSourceCard title="Scan Text" icon={ScanSourceIcon} buttonType="outline" buttonText="Add Scans" />,
    title: 'Scan Pages'
  }
};

export const ImportDialog = React.memo(function ImportDialog() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const aiStoryFlowVariant = useFeatureVariant(FeatureNameEnum.AI_STORY_FLOW);
  const aiStoryEnabled = isFeatureVariantReady(aiStoryFlowVariant) && aiStoryFlowVariant.variant;

  const handleClose = useCallback(() => {
    dispatch(importActions.closeImportDialog());
  }, [dispatch]);

  const { source, isModalOpen } = useSelector(importSelectors.getImportState);
  const isIntegrationSource = Object.values<string | null>(IntegrationService).includes(source);
  const isIntegrationAuthorized = useSelector(state =>
    isIntegrationSource ? integrationSelectors.getAuthByService(source as IntegrationService)(state).authorized : false
  );
  const enabledIntegrations = useAvailableIntegrations();

  const isFixedSize = !isIntegrationSource || !isIntegrationAuthorized;

  const currentUser = useSelector(state => state.auth.user as IUser);
  const crossSellVoiceover = !isPremium(currentUser) || (isPremium(currentUser) && isStripe(currentUser));

  const handleVoiceOverCrossSell = () => {
    logSegmentEvent('web_app_voiceover_cross_sell_pressed', { source: 'new' });
    window.open('https://studio.speechify.com/?fromWebApp=true', '_blank');
  };

  const handleFileDragEnter = useCallback(
    (e: DragEvent) => {
      e.preventDefault();

      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
        if (e.dataTransfer.types.includes('Files') && !document.querySelector('[data-dropzone="true"]')) {
          dispatch(importActions.openImportDialog());
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.body.addEventListener('dragenter', handleFileDragEnter);
    return () => document.body.removeEventListener('dragenter', handleFileDragEnter);
  }, [handleFileDragEnter]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(integrationActions.fetchAuths());
    }
  }, [dispatch, isModalOpen]);

  const baseDialogHeight = crossSellVoiceover ? 680 : 620;
  const baseDialogWidth = 1050;

  const dialogTitle = source && importSourceConfig[source].title;
  const localizedDialogTitle = typeof dialogTitle === 'string' ? t(dialogTitle) : dialogTitle;

  return (
    <ModalComponent
      open={isModalOpen}
      onClose={handleClose}
      classNames="dark:bg-glass-700 max-w-[90vw] inline-flex font-ABCDiatype"
      style={{
        height: isFixedSize ? `min(${baseDialogHeight}px, 90vh)` : `max(min(${baseDialogHeight}px, 90vh), 70vh)`,
        minWidth: isFixedSize ? `min(${baseDialogWidth}px, 90vw)` : `max(min(${baseDialogWidth}px, 90vw), 70vw)`
      }}
      dialogClassNames="!z-2000"
    >
      <XIcon className="absolute right-3 top-4 m-0.5 float-right block h-6 w-6 cursor-pointer text-glass-500" aria-hidden="true" onClick={handleClose} />
      {source && (
        <ChevronLeftIcon
          className="absolute left-5 top-4 my-0.5 float-right block h-6 w-6 cursor-pointer text-glass-500"
          aria-hidden="true"
          onClick={() => dispatch(importActions.setImportSource(null))}
        />
      )}
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex h-15 w-full justify-center items-center gap-2 border-b border-solid border-glass-300 p-4 -mb-px text-xl font-medium bg-glass-200 text-glass-700">
          {source ? localizedDialogTitle : t('New Document')}
        </div>
        {isModalOpen && (
          <div className="flex flex-col min-h-0 flex-1">
            {source ? (
              importSourceConfig[source].content
            ) : (
              <div className="p-4 w-full overflow-y-auto flex flex-col min-h-0 gap-4">
                <Dropzone fullWidth className="py-[70px]" />
                <div className="flex flex-col gap-4">
                  <div className={twMerge('grid gap-4', enabledIntegrations.length < Object.values(IntegrationService).length ? 'grid-cols-3' : 'grid-cols-4')}>
                    {Object.entries(importSourceConfig)
                      .filter(([source]) => (enabledIntegrations as string[]).includes(source))
                      .map(([key, config]) => (
                        <div
                          key={key}
                          className={twMerge(
                            'border-glass-300 hover:border-glass-hovered-300 active:border-glass-pressed-300 transition-colors',
                            'border rounded-[10px] cursor-pointer'
                          )}
                          onClick={() => dispatch(importActions.setImportSource(key as ImportSource))}
                        >
                          {config.card}
                        </div>
                      ))}
                  </div>
                  <div className={twMerge('grid gap-4', aiStoryEnabled ? 'grid-cols-4' : 'grid-cols-3')}>
                    {Object.entries(importSourceConfig)
                      .filter(([source]) => !Object.values<string>(IntegrationService).includes(source) && (source !== 'aiStory' || aiStoryEnabled))
                      .map(([key, config]) => (
                        <div
                          key={key}
                          className={twMerge(
                            'border-glass-300 hover:border-glass-hovered-300 active:border-glass-pressed-300 transition-colors',
                            'border rounded-[10px] cursor-pointer'
                          )}
                          onClick={() => dispatch(importActions.setImportSource(key as ImportSource))}
                        >
                          {config.card}
                        </div>
                      ))}
                  </div>
                </div>
                {crossSellVoiceover && (
                  <div
                    className="flex gap-2 items-center py-1 px-2 rounded-xl bg-glass-0 hover:bg-electric-200 transition-colors cursor-pointer"
                    onClick={handleVoiceOverCrossSell}
                  >
                    <VoiceoverIcon className="w-9 h-9 inline-block" />
                    <span className="text-base leading-6 text-glass-700 font-medium">
                      {t('Create professional voice-overs try our audio and video editing studio')}
                    </span>
                    <button className="font-bold text-electric-400 text-sm flex items-center gap-2 ml-auto">
                      {t('Try out Speechify Studio')}
                      <ArrowRightIcon className="w-5 h-5" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </ModalComponent>
  );
});
