import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

export type WebLinkImportModalData = {
  isWebLinkModalOpen: boolean;
  initialUrl: string;
  setIsWebLinkModalOpen: (open: boolean) => void;
};

export function useIsWebLinkImportModalOpen() {
  const [data, setData] = useState<Pick<WebLinkImportModalData, 'isWebLinkModalOpen' | 'initialUrl'>>({
    isWebLinkModalOpen: false,
    initialUrl: ''
  });

  const { isWebLinkModalOpen, initialUrl } = data;

  const setIsWebLinkModalOpen = useCallback<WebLinkImportModalData['setIsWebLinkModalOpen']>(
    open =>
      setData(prev => {
        const initialUrl = open ? prev.initialUrl : ''; // reset initialUrl when closing modal
        return {
          isWebLinkModalOpen: open,
          initialUrl: initialUrl
        };
      }),
    [setData]
  );

  const router = useRouter();

  useEffect(() => {
    if (router.query.importUrl && typeof router.query.importUrl === 'string' && router.query.importUrl !== '') {
      const { importUrl, ...query } = router.query;
      const decodedUrl = importUrl;

      setData({
        isWebLinkModalOpen: true,
        initialUrl: decodedUrl
      });

      // clean up importUrl query param
      router.push(
        {
          pathname: router.pathname,
          query
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  return {
    isWebLinkModalOpen,
    initialUrl,
    setIsWebLinkModalOpen
  };
}
