import React, { Fragment, useEffect, useMemo, useState } from 'react';

import Button from 'components/elements/Button';
import TextInput from 'components/elements/TextInput';
import { CloseIcon } from 'components/experience/controls/voiceSelector/icons';
import { useBrowserInfo } from 'hooks/useBrowserInfo';
import { logVariant, useFeatureFlag, FeatureNameEnum } from 'hooks/useFeatureFlags';
import { useTranslation } from 'hooks/useTypedTranslation';
import Trans from 'next-translate/Trans';
import { useForm } from 'react-hook-form';
import { RootState, useSelector } from 'store';
import { ensureFullUrl } from 'utils';
import { logSegmentEvent } from 'utils/analytics';
import { BrowserNameEnum, isOnMicrosoftEdgeBrowser } from 'utils/browser';
import { checkIfEdgeExtensionIsInstalled, ExtensionType, getRecommendedExtensionToInstall } from 'utils/extension';
import * as yup from 'yup';

import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';

interface ModalProps {
  onSubmit?: (url: string) => void;
  onClose?: () => void;
  open: boolean;
  initialUrl: string;
}

const browsersForExtension: Array<string> = [
  BrowserNameEnum.Chrome,
  BrowserNameEnum.Brave,
  BrowserNameEnum.Arc,
  BrowserNameEnum.MicrosoftEdge,
  BrowserNameEnum.Opera
];

const upsellVideoShownLocalStorageKey = 'upsell_extension_video_on_weblink_shown';
const upsellTextShownLocalStorageKey = 'upsell_extension_text_on_weblink_shown';

export const WebLinkModal: React.FC<ModalProps> = ({ onSubmit, onClose, open = false, initialUrl }) => {
  const { t } = useTranslation('common');

  const schema = yup.object().shape({
    url: yup.string().required(t('A valid web link is required')).url(t('A valid web link is required')).transform(ensureFullUrl) // Transforming input to ensure it includes a protocol
  });
  type WebLinkFormFields = { url: string };

  const {
    formState: { errors },
    handleSubmit: handleSubmitWrapper,
    register,
    setFocus
  } = useForm<WebLinkFormFields>({
    values: {
      url: initialUrl
    },
    resolver: yupResolver(schema),
    shouldUnregister: true
  });

  const { variant: showExtensionUpsellVariant, isLoading: showExtensionUpsellVariantLoading } = useFeatureFlag(
    FeatureNameEnum.SHOW_EXTENSION_UPSELL_ON_WEB_LINK
  );

  const { browserName, browserIcon } = useBrowserInfo();

  const [upsellExtensionVideoOnWebLinkShown, setUpsellExtensionVideoOnWebLinkShown] = useState(Boolean(localStorage.getItem(upsellVideoShownLocalStorageKey)));

  const [upsellExtensionTextOnWebLinkShown, setUpsellExtensionTextOnWebLinkShown] = useState(Boolean(localStorage.getItem(upsellTextShownLocalStorageKey)));

  const [hiddenUpsellExtension, setHiddenUpsellExtension] = useState(false);

  const chromeExtensionInstalled = useSelector((state: RootState) => state.usage.userData.chromeExtension !== null);

  const [edgeExtensionInstalled, setEdgeExtensionInstalled] = useState(false);
  useEffect(() => {
    checkIfEdgeExtensionIsInstalled().then(isInstalled => {
      setEdgeExtensionInstalled(isInstalled);
    });
  }, []);

  const extensionNotInstalled = useMemo(() => {
    if (isOnMicrosoftEdgeBrowser()) {
      return !chromeExtensionInstalled && !edgeExtensionInstalled;
    }
    return !chromeExtensionInstalled;
    // ESLint: React Hook useMemo has an unnecessary dependency: 'browserName'. Either exclude it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chromeExtensionInstalled, edgeExtensionInstalled, browserName]);

  const shouldShowUpsellV2 = useMemo(() => showExtensionUpsellVariant === 'videoAndText', [showExtensionUpsellVariant]);

  const isChromiumBrowser = useMemo(() => browsersForExtension.includes(browserName), [browserName]);

  const shouldShowVideo = useMemo(() => {
    return isChromiumBrowser && extensionNotInstalled && !upsellExtensionVideoOnWebLinkShown && shouldShowUpsellV2 && !hiddenUpsellExtension;
    // ESLint: React Hook useMemo has a missing dependency: 'isChromiumBrowser'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensionNotInstalled, upsellExtensionVideoOnWebLinkShown, shouldShowUpsellV2, hiddenUpsellExtension]);

  const shouldShowTextUpsell = useMemo(
    () => isChromiumBrowser && (extensionNotInstalled || !upsellExtensionTextOnWebLinkShown) && shouldShowUpsellV2 && !hiddenUpsellExtension,
    [isChromiumBrowser, extensionNotInstalled, upsellExtensionTextOnWebLinkShown, shouldShowUpsellV2, hiddenUpsellExtension]
  );

  const shouldShowControlUpsell = useMemo(
    () => isChromiumBrowser && extensionNotInstalled && !shouldShowUpsellV2 && !hiddenUpsellExtension,
    [isChromiumBrowser, extensionNotInstalled, shouldShowUpsellV2, hiddenUpsellExtension]
  );

  const recommendedExtension = useMemo(() => getRecommendedExtensionToInstall(), []);

  useEffect(() => {
    if (!showExtensionUpsellVariantLoading) {
      logVariant('showExtensionUpsellOnWebLink', showExtensionUpsellVariant);
    }
  }, [showExtensionUpsellVariant, showExtensionUpsellVariantLoading]);

  useEffect(() => {
    if (open) {
      logSegmentEvent('web_app_import_modal_opened', { chrome_extension_upsell_shown: extensionNotInstalled });
      setHiddenUpsellExtension(false);
      localStorage.setItem(upsellVideoShownLocalStorageKey, 'true');
    }
  }, [extensionNotInstalled, open]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFocus('url');
      }, 250);
    }
  }, [setFocus, open]);

  // hide upsell extension when initialUrl is set since it means the user is coming from the extension
  useEffect(() => {
    if (initialUrl !== '') {
      setHiddenUpsellExtension(true);
    }
  }, [initialUrl]);

  const handleSubmit = (data: WebLinkFormFields) => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSubmit(data.url);
  };

  const hideUpsellExtension = () => {
    setUpsellExtensionVideoOnWebLinkShown(true);
    setHiddenUpsellExtension(true);
  };

  const onSkipUpsellExtension = () => {
    logSegmentEvent('web_app_import_modal_extension_link_skipped');
    hideUpsellExtension();
  };

  const onTryNowExtension = () => {
    logSegmentEvent('web_app_import_modal_extension_link_clicked');
    window.open(recommendedExtension.extensionStoreUrl, '_blank');
    hideUpsellExtension();
  };

  const onCloseTextUpsellExtension = () => {
    setUpsellExtensionTextOnWebLinkShown(true);
    setHiddenUpsellExtension(true);
    localStorage.setItem(upsellTextShownLocalStorageKey, 'true');
  };

  const onModalClose = () => {
    setUpsellExtensionVideoOnWebLinkShown(true);
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className={`fixed inset-0 z-50 overflow-y-visible`} open={open} onClose={onModalClose}>
        <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block w-full transform overflow-hidden align-bottom transition-all sm:max-w-xl sm:align-middle`}>
              {shouldShowVideo && (
                <div className="mb-4 rounded-lg bg-white text-left shadow sm:mt-8">
                  <div className="overflow-hidden rounded-md">
                    <div className="flex flex-col">
                      <div className="flex gap-2 space-y-3 bg-electric-200 px-4 py-3">
                        <div className="rounded-full bg-electric-250 p-[2px]">{browserIcon}</div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Listen to any website with Speechify extension')}</h3>
                      </div>

                      <video
                        src="https://storage.googleapis.com/speechify-website-assets/new-speechify/upsell-extension.mp4"
                        preload="auto"
                        autoPlay
                        loop
                        playsInline
                      />
                    </div>

                    <div className="flex flex-row-reverse bg-glass-300 px-6 py-3 text-right">
                      <Button title={t('Try now')} onClick={onTryNowExtension} />
                      <Button className="mr-2 !bg-glass-200" color="default" onClick={onSkipUpsellExtension} title={t('Skip')} />
                    </div>
                  </div>
                </div>
              )}

              {!shouldShowVideo && (
                <form onSubmit={handleSubmitWrapper(handleSubmit)} className="mb-4 rounded-lg bg-white text-left shadow sm:mt-8">
                  <div className="overflow-hidden rounded-md">
                    <div className="space-y-6 px-4 py-6 sm:p-6">
                      {shouldShowTextUpsell && (
                        <div className="align-items flex flex transform gap-4 overflow-hidden rounded-lg border border-electric-250 bg-electric-200 px-3 py-4 text-left align-bottom shadow transition-all">
                          {!extensionNotInstalled && (
                            <CloseIcon className="absolute right-2 top-2 fill-glass-600 hover:cursor-pointer" onClick={onCloseTextUpsellExtension} />
                          )}
                          <div>
                            <div className="rounded-full bg-electric-250 p-[2px]">{browserIcon}</div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <span className="text-glass font-bold text-glass-700">💡 Did you know?</span>
                            <span className="text-xs">
                              <Trans
                                i18nKey="common:Use the Speechify Extension on Browser to listen on any website"
                                components={[
                                  extensionNotInstalled ? <span className="cursor-pointer font-bold text-electric-400" onClick={onTryNowExtension} /> : <></>,
                                  <>{browserName}</>
                                ]}
                              />
                            </span>
                          </div>
                        </div>
                      )}

                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Paste Web Link')}</h3>
                      </div>

                      <div>
                        <div className="mt-1">
                          <TextInput
                            error={errors.url}
                            // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                            name="url"
                            placeholder="https://"
                            {...register('url')}
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500" id="email-description">
                          {t('paste_a_link')}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row-reverse px-6 py-3 text-right">
                      <Button title={t('Submit')} type="submit" />
                      <Button className="mr-2" color="default" onClick={onClose} title={t('Cancel')} />
                    </div>
                  </div>
                </form>
              )}

              {shouldShowControlUpsell && (
                <div className="align-items flex transform overflow-hidden rounded-lg border border-electric-250 bg-electric-200 text-left align-bottom shadow transition-all">
                  <div className="align-items my-4 ml-6 flex justify-center">
                    <img
                      className="mr-3 h-14 w-14"
                      src={recommendedExtension.extensionType === ExtensionType.EDGE ? '/images/microsoft_edge.png' : '/images/chrome_extension_store.png'}
                    />
                    <div className="">
                      <div className="text-sm font-medium">
                        {t('Try Chrome Extension', {
                          browser: recommendedExtension.extensionType === ExtensionType.EDGE ? 'Edge' : 'Chrome'
                        })}
                      </div>
                      <div className="text-xs text-gray-500">{t('Listen to sites, like Google Docs, Gmail, Wikipedia, Fanfiction, New York Times')}</div>
                    </div>
                  </div>
                  <div className="mr-4 flex w-1/3 items-center justify-end sm:mr-6">
                    <button
                      className="flex h-10 items-center justify-center rounded border border-electric-300 px-4 py-2 text-sm font-medium text-electric-400"
                      onClick={onTryNowExtension}
                    >
                      {t('Install Now')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WebLinkModal;
